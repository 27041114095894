import { get, set } from './storage-util';
import { logger } from './logger';
const BLOCKS_SYNC_TIMESTAMP_DIFF = 'krt_blocks_sync_timestamp_diff';

/**
 * browser と server 間の時間を同期するための関数。
 * 時間を同期するため、"非同期"で、
 *   diff := browser time - server time
 * を localstorage に保存する。
 *
 * それで、synced browser time := browser time + diff = server time
 * を計算すればよい。
 */
function syncDateTimeWithServer({ trackUrl }: { trackUrl: string }) {
  const setToLocal = set(window.localStorage);
  const xhr = new XMLHttpRequest();
  xhr.open('GET', `${trackUrl}timestamp`);
  xhr.onerror = () => {
    window.console.error(`[blocks.timestamp] error status: ${xhr.status}`);
    setToLocal(BLOCKS_SYNC_TIMESTAMP_DIFF, 0, true);
  };
  xhr.onload = () => {
    try {
      const serverTimestamp = new Date(xhr.responseText);
      const diff = Date.now() - serverTimestamp.getTime();
      setToLocal(BLOCKS_SYNC_TIMESTAMP_DIFF, diff, true);
      logger.info(`[blocks.timestamp] synced: ${diff}`);
    } catch (e) {
      setToLocal(BLOCKS_SYNC_TIMESTAMP_DIFF, 0, true);
    }
  };
  xhr.send();

  return {
    toValue() {
      return getDateTimeSyncedWithServer(Date.now());
    },
  };
}

/**
 * syncDateTimeWithServer によって、browser と server 間の時間が同期済みであれば、
 *   browser time + (server time - browser time) を返す。
 * 動機済みでなければ、
 *   browser time を返す。
 *
 * 想定されるケース
 * 1. 正常系: 初回時 → browser time
 * 2. 正常系: 前回Sync済み → synced browser time
 * 4. 異常系: localStorageのDifffが改ざんされた場合 → browser time
 */
function getDateTimeSyncedWithServer(dateTime: number): number {
  const getFromLocal = get(window.localStorage);
  const diff = getFromLocal<number>(BLOCKS_SYNC_TIMESTAMP_DIFF);
  if (!diff || isNaN(diff)) return dateTime;
  return dateTime + diff;
}

function isOn(scheduleTimeRange: readonly string[] | undefined): boolean {
  // scheduler が設定されてなかったら常時公開
  // FIXME: ちょい後方互換として undefined も許容
  if (!scheduleTimeRange || scheduleTimeRange.length < 2) return true;
  const current = getDateTimeSyncedWithServer(Date.now());
  const [startDate, endDate] = scheduleTimeRange;
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();
  if (start < current && current < end) {
    return true;
  }
  return false;
}

export { isOn, getDateTimeSyncedWithServer, syncDateTimeWithServer, BLOCKS_SYNC_TIMESTAMP_DIFF };
