const TEMPLATE_SYNTAX_START = '#{';
const TEMPLATE_SYNTAX_END = '}';
const TEMPLATE_SYNTAX_REGEXP = `${TEMPLATE_SYNTAX_START}(.*?)${TEMPLATE_SYNTAX_END}`;
const JS_IDENTIFIER = '[a-zA-Z_$][0-9a-zA-Z_$]*';
const JS_TEMPLATE_LITERAL_REGEXP = `^(?<tag>${JS_IDENTIFIER})?\`(?<template>.*)\`$`;
const JS_TEMPLATE_LITERAL_EXPRESSION_REGEXP = '\\${(.*?)}';
function validateExpr(expr) {
    try {
        new Function(`return ${expr}`);
        return true;
    }
    catch {
        return false;
    }
}
export function convertRawExpressionToDynamicBlockTemplateSimple(rawExpression) {
    const matched = rawExpression.match(JS_TEMPLATE_LITERAL_REGEXP);
    if (matched) {
        const { template, tag } = matched.groups;
        return {
            template: template.replace(new RegExp(JS_TEMPLATE_LITERAL_EXPRESSION_REGEXP, 'g'), '#{$1}'),
            tag,
        };
    }
    else {
        return {
            template: `#{${rawExpression}}`,
        };
    }
}
export function convertDynamicBlockTemplateToRawExpressionSimple(blockTemplateText, tag) {
    let rawExpression = '';
    const matched = Array.from(blockTemplateText.matchAll(new RegExp(TEMPLATE_SYNTAX_REGEXP, 'g')));
    if (matched.length === 1 && matched[0][0] === blockTemplateText) {
        rawExpression = blockTemplateText.slice(2, -1);
    }
    else if (matched.length > 0) {
        rawExpression = `${tag ?? ''}\`${blockTemplateText.replace(new RegExp(TEMPLATE_SYNTAX_REGEXP, 'g'), '${$1}')}\``;
    }
    return rawExpression && validateExpr(rawExpression) ? rawExpression : null;
}
export function hasDynamicBlockTemplateLiteral(text) {
    return /#{.*?}/.test(text);
}
export const KRT_TEXT_COMPATIBLE_TEMPLATE_TAG = 'template';
