export default (function (tracker) {
    var visibleStart = Date.now();
    var hiddenStart = Date.now();
    document.addEventListener('visibilitychange', function () {
        var state = document.visibilityState;
        if (state == 'visible') {
            visibleStart = Date.now();
            var hiddenElapsedTime = visibleStart - hiddenStart;
            tracker.track('visibilitychange', {
                state: state,
                hiddenElapsedTime: hiddenElapsedTime,
            });
        }
        else if (state == 'hidden') {
            hiddenStart = Date.now();
            var visibleElapsedTime = hiddenStart - visibleStart;
            tracker.track('visibilitychange', {
                state: state,
                visibleElapsedTime: visibleElapsedTime,
            });
        }
    });
});
