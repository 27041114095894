import envData from '~data/data';
import { WindowWhenLoadPublicPreviewScript } from '../../public_preview_client';

/**
 * ?krt_public_preview=1 というフラグがある場合には、public preview clientのjsのurlを返す
 * jsのurlはenvDataに埋め込まれたものを使う(URLからは取り出さない)
 * @param currentUrl
 */
export function parsePublicPreviewClientURL(currentUrl: string): string | undefined {
  const url = new URL(currentUrl);
  const preview = url.searchParams.has('krt_public_preview');
  if (!preview) {
    return;
  }
  if (!envData.publicPreviewConfig) {
    return;
  }
  return envData.publicPreviewConfig.clientJsUrl;
}

function loadScript(scriptUrl: string, onLoad: () => void): void {
  const append = () => {
    const script = document.createElement('script');
    script.addEventListener('load', onLoad);
    script.src = scriptUrl;
    document.body.appendChild(script);
    document.removeEventListener('DOMContentLoaded', append);
  };
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', append);
  } else {
    append();
  }
}

/**
 * PreviewClientをロードして実行する
 * @param previewClientScriptUrl
 */
export function runPublicPreviewClient(previewClientScriptUrl: string): void {
  loadScript(previewClientScriptUrl, () => {
    const windowAfterLoadPublicPreviewScript = window as WindowWhenLoadPublicPreviewScript;
    if (windowAfterLoadPublicPreviewScript.krtBlocksPublicPreview) {
      windowAfterLoadPublicPreviewScript.krtBlocksPublicPreview.start({
        apiKey: envData.krt_rewrite_config.apiKey,
        configBaseUrl: envData.publicPreviewConfig?.baseUrl,
      });
    }
  });
}
