var _a;
export { default as FixedWindowCalculator } from './FixedWindowCalculator';
export { default as SlidingWindowCalculator } from './SlidingWindowCalculator';
import dayWindow from './DayWindow';
import weekWindow from './WeekWindow';
export var WindowType;
(function (WindowType) {
    WindowType["DAY"] = "DAY";
    WindowType["WEEK"] = "WEEK";
})(WindowType || (WindowType = {}));
var windows = (_a = {},
    _a[WindowType.DAY] = dayWindow,
    _a[WindowType.WEEK] = weekWindow,
    _a);
export function getWindowRange(type, date) {
    if (date === void 0) { date = new Date(); }
    var window = windows[type];
    var range = window.getRange(date);
    return range;
}
