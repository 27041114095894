import { isNumberValue, isStringValue } from '../guard';
import log from '../log';
import { makeReduceKey } from '../window/reduceKey';
var PartialMatchStats = /** @class */ (function () {
    function PartialMatchStats() {
    }
    PartialMatchStats.prototype.dataType = function () {
        return 'string';
    };
    PartialMatchStats.prototype.initialValue = function () {
        return { v: 0, k: 0 };
    };
    PartialMatchStats.prototype.calculateFromValue = function (oldValue, value, option) {
        if (!isStringValue(value) || !option)
            return oldValue;
        try {
            if (this.partialMatch(option, value)) {
                var newValue = this.calculate(oldValue);
                return newValue;
            }
            else {
                var newValue = this.calculate(oldValue, 0);
                return newValue;
            }
        }
        catch (err) {
            log('Failed to calculate the stats (partial match).', err);
            return oldValue;
        }
    };
    PartialMatchStats.prototype.calculateFromStats = function (oldValue, value) {
        var newValue = this.calculate(oldValue, value.v);
        return newValue;
    };
    PartialMatchStats.prototype.gc = function (values) {
        return values;
    };
    PartialMatchStats.prototype.calculate = function (oldValue, value) {
        if (value === void 0) { value = 1; }
        oldValue.v = isNumberValue(oldValue.v) ? oldValue.v : 0;
        value = isNumberValue(value) ? value : 0;
        var newValue = { v: 0, k: makeReduceKey() };
        newValue.v = oldValue.v + value;
        return newValue;
    };
    PartialMatchStats.prototype.partialMatch = function (option, value) {
        if (!option.text) {
            throw new Error('Partial Match condition is not defined.');
        }
        return value.includes(option.text);
    };
    return PartialMatchStats;
}());
export default new PartialMatchStats();
