import { isNumberValue } from '../guard';
import { makeReduceKey } from '../window/reduceKey';
var SumStats = /** @class */ (function () {
    function SumStats() {
    }
    SumStats.prototype.dataType = function () {
        return 'number';
    };
    SumStats.prototype.initialValue = function () {
        return { v: 0, k: 0 };
    };
    SumStats.prototype.calculateFromValue = function (oldValue, value) {
        var newValue = this.calculate(oldValue, value);
        return newValue;
    };
    SumStats.prototype.calculateFromStats = function (oldValue, value) {
        var newValue = this.calculate(oldValue, value.v);
        return newValue;
    };
    SumStats.prototype.gc = function (values) {
        return values;
    };
    SumStats.prototype.calculate = function (oldValue, value) {
        oldValue.v = isNumberValue(oldValue.v) ? oldValue.v : 0;
        value = isNumberValue(value) ? value : 0;
        var newValue = { v: 0, k: makeReduceKey() };
        newValue.v = oldValue.v + value;
        return newValue;
    };
    return SumStats;
}());
export default new SumStats();
