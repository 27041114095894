import { makeReduceKey } from '../window/reduceKey';
var PrevStats = /** @class */ (function () {
    function PrevStats() {
    }
    PrevStats.prototype.dataType = function () {
        return 'any';
    };
    PrevStats.prototype.initialValue = function () {
        return { v: undefined, k: 0 };
    };
    PrevStats.prototype.calculateFromValue = function (oldValue, value) {
        if (oldValue.p == value) {
            return oldValue;
        }
        var newValue = { v: undefined, k: makeReduceKey() };
        newValue.v = oldValue.p;
        newValue.p = value;
        return newValue;
    };
    PrevStats.prototype.calculateFromStats = function (oldValue, value) {
        var newValue = { v: undefined, k: makeReduceKey() };
        // それぞれのp値に差がない場合は、一つ前の値も変化しないということなので古い統計情報を元に新しい統計情報を作る
        if (oldValue.p == value.p) {
            newValue.v = oldValue.v;
            newValue.p = oldValue.p;
            return newValue;
        }
        // 一つ前の値がない場合は、古い統計情報に含まれるp値を一つ前の値として使う
        if (value.v == null) {
            newValue.v = oldValue.p;
            newValue.p = value.p;
            return newValue;
        }
        return value;
    };
    PrevStats.prototype.gc = function (values) {
        // 最新の統計情報からvがnullでない要素までの配列を切り出す
        var index = values.reduce(function (acc, v, i) {
            if (acc > -1)
                return acc;
            return v.v != null ? i : -1;
        }, -1);
        return index > -1 ? values.splice(0, index + 1) : values;
    };
    return PrevStats;
}());
export default new PrevStats();
