// 日付単位で統計情報を圧縮する際に利用するキー（タイムスタンプ）
// msecからsecに変換し、JSON化した際のバイト数消費を抑える（3バイト）
export function makeReduceKey() {
    return toReduceKey(Date.now());
}
export function toReduceKey(msec) {
    return Math.floor(msec / 1000);
}
export function fromReduceKey(sec) {
    return sec * 1000;
}
