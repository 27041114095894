import { isNumberValue } from '../guard';
import { makeReduceKey } from '../window/reduceKey';
var MinStats = /** @class */ (function () {
    function MinStats() {
    }
    MinStats.prototype.dataType = function () {
        return 'number';
    };
    MinStats.prototype.initialValue = function () {
        return { v: undefined, k: 0 };
    };
    MinStats.prototype.calculateFromValue = function (oldValue, value) {
        var newValue = this.calculate(oldValue, value);
        return newValue;
    };
    MinStats.prototype.calculateFromStats = function (oldValue, value) {
        var newValue = this.calculate(oldValue, value.v);
        return newValue;
    };
    MinStats.prototype.gc = function (values) {
        // 最初に現れる最小値のインデックスを求める
        var ret = values.reduce(function (acc, v, i) {
            if (isNumberValue(acc.v) && isNumberValue(v.v)) {
                return v.v < acc.v ? { v: v.v, i: i } : acc;
            }
            else if (isNumberValue(v.v)) {
                return { v: v.v, i: i };
            }
            else {
                return acc;
            }
        }, { v: undefined, i: -1 });
        // 最新の統計情報から最小値を含む統計情報までの配列を切り出す
        return values.splice(0, ret.i + 1);
    };
    MinStats.prototype.calculate = function (oldValue, value) {
        if (!isNumberValue(value)) {
            return oldValue;
        }
        var newValue = { v: undefined, k: makeReduceKey() };
        if (!isNumberValue(oldValue.v)) {
            newValue.v = value;
            return newValue;
        }
        else if (oldValue.v > value) {
            newValue.v = value;
            return newValue;
        }
        else {
            return oldValue;
        }
    };
    return MinStats;
}());
export default new MinStats();
