import { SlidingWindowCalculator, WindowType } from '../window';
// 1週間
var SPAN = 7;
var WeekTimeWindow = /** @class */ (function () {
    function WeekTimeWindow(storageSet) {
        this.storageSet = storageSet;
    }
    WeekTimeWindow.prototype.getCalculator = function () {
        return new SlidingWindowCalculator(this.storageSet, WindowType.DAY, SPAN);
    };
    WeekTimeWindow.prototype.getKey = function () {
        return 'WEEK';
    };
    return WeekTimeWindow;
}());
export { WeekTimeWindow };
