type CookieOptions = {
  maxAge?: number;
  path?: string;
  domain?: string;
  expires?: Date;
  secure?: boolean;
};

type CookieObject = {
  [key: string]: string;
};

/**
 * Set or get cookie `name` with `value` and `options` object.
 */
const set = (name: string, value: string, options: CookieOptions): void => {
  let str = encode(name) + '=' + encode(value);

  if (null == value) options.maxAge = -1;

  if (options.maxAge) {
    options.expires = new Date(new Date().getTime() + options.maxAge);
  }

  if (options.path) str += '; path=' + options.path;
  if (options.domain) str += '; domain=' + options.domain;
  if (options.expires) str += '; expires=' + options.expires.toUTCString();
  if (options.secure) str += '; secure';
  str += '; samesite'; // samesiteはdefault設定に

  document.cookie = str;
};

/**
 * Return all cookies.
 */
const all = (): CookieObject => {
  let str;
  try {
    str = document.cookie;
  } catch (err) {
    if (typeof console !== 'undefined' && typeof console.error === 'function') {
      console.error((err as any).stack || err);
    }
    return {};
  }
  return parse(str);
};

const get = (name: string): string => {
  return all()[name];
};

const parse = (str: string): CookieObject => {
  const obj: CookieObject = {};
  const pairs = str.split(/ *; */);
  let pair;
  if ('' == pairs[0]) return obj;
  for (let i = 0; i < pairs.length; ++i) {
    pair = pairs[i].split('=');
    obj[decode(pair[0])] = decode(pair[1]);
  }
  return obj;
};

const encode = (value: string): string => {
  try {
    return encodeURIComponent(value);
  } catch (e) {
    console.debug('error `encode(%o)` - %o', value, e);
    return '';
  }
};

const decode = (value: string): string => {
  try {
    return decodeURIComponent(value);
  } catch (e) {
    console.debug('error `decode(%o)` - %o', value, e);
    return '';
  }
};

// edgeのcookie取得の場合、同じ名前のkeyが複数あったら、先に書いてあった方を優先する
// rewriteの場合、後に書いてあったものが優先される
// see also https://github.com/plaidev/edgejs-plugins/blob/main/kit/utils/cookie.ts#L36-L46
const _SEP = '=';
const getEdge = (key: string): string | undefined => {
  const cookie_string = document.cookie;
  const found = find(cookie_string.split(/ *; */), x => startsWith(x, key + _SEP));
  if (found) {
    return found.split(_SEP)[1] as string;
  }
};

// polyfill
const find = (arr: string[], cb: (key: string) => boolean): string | undefined => {
  for (let i = 0; i < arr.length; i++) {
    if (cb(arr[i])) {
      return arr[i];
    }
  }
};

const startsWith = (str: string, search: string): boolean => {
  return str.slice(0, search.length) === search;
};

export { CookieOptions, get, set, getEdge };
