export var schema = {
    date: '2',
    api_token: '3',
    user_id: '4',
    email: '5',
    visitor_id: '6',
    session_id: '7',
    path: '8',
    host: '9',
    'access.os.name': 'a',
    'access.os.version': 'b',
    'access.os.all': 'c',
    'access.engine.name': 'd',
    'access.engine.version': 'e',
    'access.browser.name': 'f',
    'access.browser.version': 'g',
    'access.browser.major': 'h',
    'access.browser.all': 'i',
    'access.language': 'j',
    'access.screen.availWidth': 'k',
    'access.screen.availHeight': 'l',
    'access.screen.availTop': 'm',
    'access.screen.availLeft': 'n',
    'access.screen.pixelDepth': 'o',
    'access.screen.colorDepth': 'p',
    'access.screen.width': 'q',
    'access.screen.height': 'r',
    'access.referrer.protocol': 's',
    'access.referrer.host': 't',
    'access.referrer.path': 'u',
    'access.referrer.anchor': 'v',
    'access.in_referrer.protocol': 'w',
    'access.in_referrer.host': 'x',
    'access.in_referrer.path': 'y',
    'access.in_referrer.anchor': 'z',
    'access.land_uri.protocol': 'A',
    'access.land_uri.host': 'B',
    'access.land_uri.path': 'C',
    'access.land_uri.anchor': 'D',
    'access.uri.protocol': 'E',
    'access.uri.host': 'F',
    'access.uri.path': 'G',
    'access.uri.anchor': 'H',
    'access.title': 'I',
};
