import { Raw, Builder } from './config_types';
import * as controlGroup from './control_group';
import * as scheduler from './scheduler';
// webpackでビルドされるためdirect requireで直接取得している
import { matcher2 } from 'rewrite-common';
import { find } from './polyfill/find';
import { dimensionMatcher, DimensionValue } from 'rewrite-dimension-lib';
import { edgeDimensionTypes } from 'rewrite-common';
import type { State } from './store';
import { SKIPPED_AREA_IDS_ATTR } from './render';

function isNotGate(logicGate: Raw.LogicGate): boolean {
  switch (logicGate) {
    case '$or':
    case '$and':
      return false;
    case '$nor':
    case '$nand':
      return true;
  }
}

/**
 * TODO: 条件のand, orを切り替えられるようにしたらロジックも修正必要
 * @param condition
 * @param currentDimensions
 */
function checkBlocksSegmentMatchedToConditions(
  condition: Raw.BlocksSegmentCondition | undefined,
  currentDimensions: Record<string, DimensionValue>,
): boolean {
  // 条件自体が空、もしくはセグメント条件の集合が空なら適用
  if (!condition || condition.segmentSet.length === 0) return true;

  // 外側はandなのでevery
  const result = condition.segmentSet.every(set => {
    // セグメントの集合が空なら適用
    if (set.segments.length === 0) return true;

    // 内側はorなのでsome
    const result = set.segments.some(segment => {
      // 条件が空なら適用
      if (segment.conditions.length === 0) return true;

      const conditions = segment.conditions.map(c => {
        return c.dimensionConditions.map(dimensionCondition => {
          return {
            id: dimensionCondition.dimensionId,
            compare: dimensionCondition.compare,
            isRegex: dimensionCondition.isRegex,
            value: dimensionCondition.value,
          };
        });
      }) as edgeDimensionTypes.MatchConditions;

      return dimensionMatcher.isDimensionMatchToBlocksSegment(conditions, currentDimensions);
    });

    // orならそのまま、norなら反転
    return isNotGate(set.logicGate) ? !result : result;
  });

  // andならそのまま、nandなら反転
  return isNotGate(condition.logicGate) ? !result : result;
}

function checkSegmentsMatchedToConditions(
  conditions: readonly Raw.SegmentCondition[],
  segments: readonly string[] | null,
  options: { forceMatch: boolean },
): boolean {
  if (options.forceMatch) return true;
  // 条件が空なら必ず適用
  if (conditions.length === 0) return true;
  // localSegmentsがnull(1PV目=keyが存在しない)のときは不一致とする。
  // 空([])の場合はsegmentConditionの中に$not条件を含んだものを考慮するために通す
  if (!segments) return false;

  return conditions.every(condition => {
    if (condition.flag || condition.logicGate === '$and') {
      // cond.flagがtrueの場合: localSegmentsにsegmentが一つ以上含まれる
      return condition.segmentSet.some(segment => segments.indexOf(segment) >= 0);
    } else {
      // cond.flagがfalseの場合: localSegmentsにすべてのsegmentが含まれていない
      return condition.segmentSet.every(segment => segments.indexOf(segment) < 0);
    }
  });
}

function calcRandomValue({ conditionId, uid }: { conditionId: string; uid: string }): number {
  // conditionごとのcontrol groupを生成
  const x = parseInt(uid.slice(-3), 16);
  // conditionIdが十分にばらけている想定
  // TODO 現状conditionIDがランダムな文字列 (36進数) を想定しているが、仕様によって処理を変える
  const y = parseInt(conditionId.toLowerCase().slice(-3), 36);
  return x + y;
}

function extractMatchedPageGroup(
  pageGroupId: string,
  pageGroups: readonly Raw.PageGroup[],
): Raw.PageGroup | undefined {
  const pageGroup = find(pageGroups, pg => pg.pageGroupId === pageGroupId);
  if (pageGroup) return pageGroup;

  const pageGroupsMatchedToUrl = pageGroups.filter(pageGroup =>
    matcher2.isMatch(location.href, pageGroup.urlCondition),
  );
  if (pageGroupsMatchedToUrl.length === 0) return undefined;
  const topPriorityPageGroup = pageGroupsMatchedToUrl.reduce((a, b) =>
    a.priority < b.priority ? a : b,
  );
  return topPriorityPageGroup;
}

type PageGroupLike = {
  priority: string;
  urlCondition: Raw.UrlCondition2;
};

function extractMatchedPageGroups<T extends PageGroupLike>(
  pageGroups: readonly T[],
): T[] | undefined {
  const pageGroupsMatchedToUrl = pageGroups.filter(pageGroup =>
    matcher2.isMatch(location.href, pageGroup.urlCondition),
  );
  const sortedPageGroups = pageGroupsMatchedToUrl.sort((a, b) =>
    a.priority < b.priority ? -1 : 1,
  );
  return sortedPageGroups.length > 0 ? sortedPageGroups : undefined;
}

/**
 *
 * @deprecated リデザインリリース後に削除予定
 */
function extractMatchedAndFormattedConditions(
  pageGroup: Raw.PageGroup,
  uid: string,
  segments: readonly string[] | null,
  conditionVal: number,
  isInControlGroupForAll: boolean,
): readonly Builder.Condition[] | undefined {
  const { conditions } = pageGroup;
  const currentDimensions = dimensionMatcher.dimensionStorage.getAllDimensions();
  const filteredConditions = conditions
    .filter(c => !isInControlGroupForAll || (isInControlGroupForAll && c.isOriginal))
    .filter(c =>
      checkBlocksSegmentMatchedToConditions(
        c.blocksSegmentCondition || undefined,
        currentDimensions,
      ),
    )
    .filter(c =>
      checkSegmentsMatchedToConditions(c.segmentConditions || [], segments, { forceMatch: false }),
    ) // segmentにmatchしたものだけを取り出す
    .filter(c => {
      return dimensionMatcher.isDimensionMatchToConditions(c.dimensionCondition, currentDimensions);
    })
    // eslint-disable-next-line compat/compat
    .filter(c => scheduler.isOn(c.scheduleTimeRange)); // schedule設定で filter
  if (filteredConditions.length === 0) return undefined;

  const prioritizeConditions = filteredConditions.sort((a, b) =>
    a.priority > b.priority ? 1 : -1,
  );

  const conditionsWithControlGroup = createConditionsWithControlGroup({
    conditions: prioritizeConditions,
    pageGroup,
    uid,
    conditionVal,
  });

  return conditionsWithControlGroup;
}

function _extractMatchedAndFormattedConditions(
  conditions: (Raw.Condition & { pageGroup: Raw.PageGroup })[],
  uid: string,
  segments: readonly string[] | null,
  conditionVal: number,
  isInControlGroupForAll: boolean,
): readonly Builder.Condition[] | undefined {
  const currentDimensions = dimensionMatcher.dimensionStorage.getAllDimensions();
  const filteredConditions = conditions
    .filter(c => !isInControlGroupForAll || (isInControlGroupForAll && c.isOriginal))
    .filter(c =>
      checkBlocksSegmentMatchedToConditions(
        c.blocksSegmentCondition || undefined,
        currentDimensions,
      ),
    )
    .filter(c =>
      checkSegmentsMatchedToConditions(c.segmentConditions || [], segments, { forceMatch: false }),
    ) // segmentにmatchしたものだけを取り出す
    .filter(c => {
      return dimensionMatcher.isDimensionMatchToConditions(c.dimensionCondition, currentDimensions);
    })
    // eslint-disable-next-line compat/compat
    .filter(c => scheduler.isOn(c.scheduleTimeRange)) // schedule設定で filter
    .filter(c => c.campaignPriority !== '' || c.campaignPriority !== undefined); // campaignPriority 空文字、または undefined なら除外
  if (filteredConditions.length === 0) return undefined;

  const prioritizeConditions = filteredConditions.sort((a, b) =>
    // campaignPriority を見て優先度を決める
    // 型的に undefined なのでキャストしてるが、実際には undefined は到達しないので気にしなくていい
    (a.campaignPriority as string) > (b.campaignPriority as string) ? 1 : -1,
  );

  const conditionsWithControlGroup = _createConditionsWithControlGroup({
    conditions: prioritizeConditions,
    uid,
    conditionVal,
  });

  return conditionsWithControlGroup;
}

function createConditionsWithControlGroup({
  conditions,
  pageGroup,
  uid,
  conditionVal,
}: {
  conditions: Raw.Condition[];
  pageGroup: Raw.PageGroup;
  uid: string;
  conditionVal: number;
}): readonly Builder.Condition[] {
  const conditionsWithIsControlGroup: Builder.Condition[] = conditions.map(c => {
    const randomVal = calcRandomValue({ uid: uid, conditionId: c.conditionId });
    const controlPatternProportion = controlGroup.calcControlPatternProportion(c.patterns);
    const hasControl = controlGroup.hasControl(controlPatternProportion);
    return {
      ...c,
      pageGroupId: pageGroup.pageGroupId,
      randomVal: randomVal,
      hasControl,
      controlPatternProportion,
      isControlForCondition: controlGroup.isInControlGroupForCondition(
        {
          randomVal: randomVal,
          hasControl,
          controlPatternProportion,
        },
        conditionVal,
      ),
    };
  });
  return conditionsWithIsControlGroup;
}

function _createConditionsWithControlGroup({
  conditions,
  uid,
  conditionVal,
}: {
  conditions: (Raw.Condition & { pageGroup: Raw.PageGroup })[];
  uid: string;
  conditionVal: number;
}): readonly Builder.Condition[] {
  const conditionsWithIsControlGroup: Builder.Condition[] = conditions.map(c => {
    const randomVal = calcRandomValue({ uid: uid, conditionId: c.conditionId });
    const controlPatternProportion = controlGroup.calcControlPatternProportion(c.patterns);
    const hasControl = controlGroup.hasControl(controlPatternProportion);
    return {
      ...c,
      pageGroupId: c.pageGroup.pageGroupId,
      randomVal: randomVal,
      hasControl,
      controlPatternProportion,
      isControlForCondition: controlGroup.isInControlGroupForCondition(
        {
          randomVal: randomVal,
          hasControl,
          controlPatternProportion,
        },
        conditionVal,
      ),
    };
  });
  return conditionsWithIsControlGroup;
}

function filterConditionByControl(
  conditions: readonly Builder.Condition[],
): readonly Builder.Condition[] {
  return conditions.filter(c => !c.isControlForCondition);
}

function extractMatchedPatterns(
  conditions: readonly Builder.Condition[],
): readonly Builder.Pattern[] {
  const original = conditions.filter(c => c.isOriginal)[0];

  const patterns = conditions
    .map(c => {
      if (c.isControlForCondition) return extractMatchedControlPattern(c, original);
      else return extractMatchedPattern(c);
    })
    .filter(p => {
      if (p === undefined) {
        return false;
      }
      return true;
    }) as Builder.Pattern[];
  return patterns;
}

function extractSkippedVariations({
  pageGroups,
  state,
}: {
  pageGroups: Raw.PageGroup[];
  state: State;
}): Builder.Variation[] {
  const skippedAreaIds = [] as string[];
  const elements = document.querySelectorAll<HTMLElement>(`[${SKIPPED_AREA_IDS_ATTR}]`);
  elements.forEach(element => {
    const ids = element.getAttribute(SKIPPED_AREA_IDS_ATTR);
    if (!ids) return;
    ids.split(',').forEach(id => {
      if (skippedAreaIds.indexOf(id) < 0) {
        skippedAreaIds.push(id);
      }
    });
  });

  const skippedVariations = [] as Builder.Variation[];
  pageGroups.forEach(pageGroup => {
    const { conditions } = pageGroup;
    const conditionsWithCondtrolGroup = createConditionsWithControlGroup({
      conditions: conditions as Raw.Condition[],
      pageGroup,
      uid: state.rewriteUid,
      conditionVal: state.conditionVal,
    });

    conditionsWithCondtrolGroup.forEach(condition => {
      const { patterns } = condition;

      patterns.forEach(pattern => {
        const { variations } = pattern;
        variations.forEach(variation => {
          if (skippedAreaIds.indexOf(variation.areaId) >= 0) {
            skippedVariations.push({
              ...variation,
              pageGroupId: condition.pageGroupId,
              conditionId: condition.conditionId,
              patternId: pattern.patternId,
              isControlForCondition: condition.isControlForCondition,
              hasControl: condition.hasControl,
            });
          }
        });
      });
    });
  });

  return skippedVariations;
}

function createVariationsForSkippedEvent(variations: Builder.Variation[]): Builder.Variation[] {
  const EMPTY_VARIATION = {
    conditionId: 'SKIPPED_AREA_EVENT',
    variationId: 'SKIPPED_AREA_EVENT',
    cssSelector: '',
    aHref: '',
    imgUrl: '',
    originImgSrc: '',
  };

  const variationsForSkippedEvent = variations.reduce((acc, v) => {
    if (acc.findIndex(x => x.areaId === v.areaId) > -1) return acc;
    acc.push({
      ...v,
      ...EMPTY_VARIATION,
    });

    return acc;
  }, [] as Builder.Variation[]);

  return variationsForSkippedEvent;
}

/**
 * pick one element from proportional array according to random value.
 * - `array` each element in array should have `proportion` property.
 * - `randamValue` has to be in `[0, sum of proportions)` = `[0, sum of proportions - 1]`.
 */
function pickRandomlyFromProportionalArray<T extends { proportion: number }>({
  randomValue,
  array,
}: {
  randomValue: number;
  array: Readonly<T[]>;
}): T | undefined {
  let proportionSum = 0;
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    proportionSum += element.proportion;
    if (randomValue < proportionSum) return element;
  }
  return undefined;
}

function extractMatchedPattern(condition: Builder.Condition): Builder.Pattern | undefined {
  /**
   * The variable `randamValue` is in `[0, 100 - controlPatternProportion)`.
   * Above condition satisfies requirements of pickRandomlyFromProportionalArray.
   * Thus, pattern should be picked, when condition.patterns has one element at least.
   */
  const randomValue = condition.randomVal % (100 - condition.controlPatternProportion);
  const pattern = pickRandomlyFromProportionalArray({
    randomValue: randomValue,
    array: condition.patterns,
  });
  if (pattern === undefined) return undefined;
  return {
    ...pattern,
    conditionId: condition.conditionId,
    isControlForCondition: condition.isControlForCondition,
    hasControl: condition.hasControl,
    pageGroupId: condition.pageGroupId,
    isOriginal: !!condition.isOriginal,
  };
}

function extractMatchedControlPattern(
  condition: Builder.Condition,
  original: Builder.Condition,
): Builder.Pattern | undefined {
  const variationListList = condition.patterns.map(p => {
    return p.variations;
  });

  // このconditionに設定されている全パターンのvariationの一覧を取得
  // ただし、同一エリアのvariationは除外
  const variations: Raw.Variation[] = [];
  variationListList.forEach(vList => {
    vList.forEach(v => {
      // エリアが重複するvariationは除外
      if (variations.filter(x => x.areaId === v.areaId).length > 0) return;
      variations.push(v);
    });
  });

  if (!original || original.patterns.length === 0) {
    // ここに入るということはoriginalが存在しない場合だが、
    // ありえないはずなので、もし来たとしても空のpatternを返す
    return {
      conditionId: condition.conditionId,
      isControlForCondition: condition.isControlForCondition,
      hasControl: condition.hasControl ? true : false,
      pageGroupId: condition.pageGroupId,
      proportion: condition.controlPatternProportion,
      patternId: 'isControl',
      variations: [],
      isOriginal: false,
    };
  }

  /**
   * オリジナルの中から、この条件で書き換えられるはずだったブロックを探して、
   * CGのvariationとしてpatternにセットする
   */
  const originalPattern = original.patterns[0];
  const newVariations = variations
    .map(v => {
      const originalVariation = originalPattern.variations.filter(
        originalV => originalV.areaId === v.areaId,
      );
      return originalVariation[0];
    })
    .filter(v => v !== undefined) as Raw.Variation[];

  const newPattern: Builder.Pattern = {
    conditionId: condition.conditionId,
    isControlForCondition: condition.isControlForCondition,
    hasControl: condition.hasControl ? true : false,
    pageGroupId: condition.pageGroupId,
    proportion: condition.controlPatternProportion,
    patternId: 'isControl',
    variations: newVariations,
    isOriginal: false,
  };

  return newPattern;
}

interface RewriteVariations {
  [key: string]: Builder.Variation;
}

function extractMatchedVariations(
  patterns: readonly Builder.Pattern[],
): readonly Builder.Variation[] {
  const rewriteVariations: RewriteVariations = {};
  patterns.forEach(p => {
    p.variations.forEach(v => {
      if (!rewriteVariations[v.cssSelector]) {
        const variation: Builder.Variation = {
          conditionId: p.conditionId,
          isControlForCondition: p.isControlForCondition,
          hasControl: p.hasControl,
          patternId: p.patternId,
          pageGroupId: p.pageGroupId,
          ...v,
        };
        rewriteVariations[v.cssSelector] = variation;
      }
    });
  });
  return Object.keys(rewriteVariations).map(key => rewriteVariations[key]);
}

function extractMatchedConditionForPrebuilder(
  conditions: readonly Raw.Condition[],
  previewConditionId: string,
): Raw.Condition | undefined {
  return find(conditions, c => c.conditionId === previewConditionId);
}

function extractMatchedPatternForPrebuilder(
  condition: Raw.Condition,
  previewPatternId: string,
): Raw.Pattern | undefined {
  return find(condition.patterns, p => p.patternId === previewPatternId);
}

function extractRewriteSrc(
  variations: readonly Raw.Variation[],
): { originImgSrcArray: string[]; cssSelectorArray: string[] } | undefined {
  const originImgSrcArray = variations.filter(v => v.originImgSrc).map(v => v.originImgSrc);
  const cssSelectorArray = variations.filter(v => v.cssSelector).map(v => v.cssSelector);
  if (originImgSrcArray.length === 0 && cssSelectorArray.length === 0) return undefined;
  return { originImgSrcArray, cssSelectorArray };
}

/**
 * ページグループにマッチするflatなconditionsの配列を返す
 * @param pageGroups
 * @param state
 * @param isInControlGroupForAll
 *
 * @deprecated リデザインリリース後に削除予定
 */
function extractMatchedConditionsByPageGroups({
  pageGroups,
  state,
  isInControlGroupForAll,
}: {
  pageGroups: Raw.PageGroup[];
  state: State;
  isInControlGroupForAll: boolean;
}): readonly Builder.Condition[] {
  // 2重配列
  const matchedConditionsArrayByPageGroups = pageGroups
    .map(pageGroup => {
      return extractMatchedAndFormattedConditions(
        pageGroup,
        state.rewriteUid,
        state.segments,
        state.conditionVal,
        isInControlGroupForAll,
      );
    })
    .filter(Boolean) as (readonly Builder.Condition[])[];
  return new Array<Builder.Condition>().concat.apply([], matchedConditionsArrayByPageGroups);
}

/**
 * ページグループにマッチするflatなconditionsの配列を返す
 * @memo リデザインリリース後にこちらを使う
 *
 * @param pageGroups
 * @param state
 * @param isInControlGroupForAll
 */
function _extractMatchedConditionsByPageGroups({
  pageGroups,
  state,
  isInControlGroupForAll,
}: {
  pageGroups: Raw.PageGroup[];
  state: State;
  isInControlGroupForAll: boolean;
}): readonly Builder.Condition[] {
  // pageGroup 依存の優先度にならないようにflatにする
  const flatConditions: (Raw.Condition & { pageGroup: Raw.PageGroup })[] = pageGroups
    .map(pageGroup => {
      return {
        pageGroup: pageGroup,
        conditions: pageGroup.conditions,
      };
    })
    .map(({ pageGroup, conditions }) => {
      return conditions.map(condition => {
        return {
          pageGroup,
          ...condition,
        };
      });
    })
    .flat();

  // ここから下の処理は同じ
  // flat にして処理をしているので、純粋に campaignPriority だけを見て判定するようになっている
  const matchedConditionsArrayByPageGroups = _extractMatchedAndFormattedConditions(
    flatConditions,
    state.rewriteUid,
    state.segments,
    state.conditionVal,
    isInControlGroupForAll,
  )?.filter(condition => condition !== undefined) as Builder.Condition[];

  return new Array<Builder.Condition>().concat.apply([], matchedConditionsArrayByPageGroups);
}

export {
  calcRandomValue,
  pickRandomlyFromProportionalArray,
  extractMatchedPageGroup,
  extractMatchedPageGroups,
  extractMatchedAndFormattedConditions,
  filterConditionByControl,
  extractMatchedPatterns,
  extractMatchedPattern,
  extractMatchedVariations,
  extractMatchedConditionForPrebuilder,
  extractMatchedPatternForPrebuilder,
  extractRewriteSrc,
  extractMatchedConditionsByPageGroups,
  _extractMatchedConditionsByPageGroups,
  _extractMatchedAndFormattedConditions,
  extractSkippedVariations,
  createVariationsForSkippedEvent,
};
