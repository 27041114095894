import { edgeDimensionTypes as t } from 'rewrite-common';
import PreviousWindowCalculator from '../window/PreviousWindowCalculator';
var PreviousSessionTimeWindow = /** @class */ (function () {
    function PreviousSessionTimeWindow(storageSet) {
        this.storageSet = storageSet;
    }
    PreviousSessionTimeWindow.prototype.getCalculator = function () {
        return new PreviousWindowCalculator(this.storageSet);
    };
    PreviousSessionTimeWindow.prototype.getKey = function () {
        // SESSION側で生成するキーをそのまま再利用する
        // そのため session.ts 側の getKey が先に呼び出されている必要がある
        return this.storageSet.timeWindowKeyStorage.getTimeWindowKey(t.TimeWindowType.SESSION);
    };
    return PreviousSessionTimeWindow;
}());
export { PreviousSessionTimeWindow };
