import { RenderFunction, RenderModule } from './types';
import { shouldRenderWhenNotRenderedYet, setAttributesToBlock } from './utils';

export const render: RenderFunction<'append-html'> = (
  element,
  { html, script, blockAttributes },
) => {
  const div = document.createElement('div');
  /**
   * html には style と html が含まれる。
   */
  div.innerHTML = html;

  /**
   * 一つでも子要素が追加したものが残っているなら再度追加しないために、全てに id つける
   */
  const children: Element[] = Array.prototype.slice.call(div.children);
  children.forEach(child => {
    element.appendChild(child);
    // elementAttributesは設定しない
    setAttributesToBlock({ element: child, blockAttributes });
  });

  // script は実行するために別で追加する
  if (!script) return;
  const scriptTag = document.createElement('script');
  scriptTag.text = `(function() {${script}})()`;
  setAttributesToBlock({ element: scriptTag, blockAttributes });
  element.appendChild(scriptTag);
};

export const create = (): RenderModule<'append-html'> => {
  return {
    render,
    shouldRender: shouldRenderWhenNotRenderedYet,
  };
};
