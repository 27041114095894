import { getWindowRange } from '.';
import { calculateAll, gc } from '../stats';
import { fromReduceKey, toReduceKey } from './reduceKey';
var DAY = 24 * 60 * 60 * 1000;
var SlidingWindowCalculator = /** @class */ (function () {
    function SlidingWindowCalculator(storageSet, windowType, span) {
        this.storageSet = storageSet;
        this.windowType = windowType;
        this.span = span;
    }
    Object.defineProperty(SlidingWindowCalculator.prototype, "allWindowRange", {
        get: function () {
            var range = getWindowRange(this.windowType);
            range.from = toReduceKey(fromReduceKey(range.to) - this.span * DAY);
            return range;
        },
        enumerable: false,
        configurable: true
    });
    SlidingWindowCalculator.prototype.calculate = function (dimension, value) {
        // 日毎の統計値を更新
        this.updateHistory(dimension, value);
        // 日毎の統計値をmergeして統計値を更新
        this.update(dimension);
    };
    SlidingWindowCalculator.prototype.change = function (tw, newKey) {
        var oldKey = this.storageSet.timeWindowKeyStorage.getTimeWindowKey(tw);
        if (oldKey && oldKey !== newKey) {
            this.storageSet.dimensionStorage.clearDimensionByTw(tw);
        }
        this.storageSet.timeWindowKeyStorage.setTimeWindowKey(tw, newKey);
        return oldKey !== newKey;
    };
    SlidingWindowCalculator.prototype.updateHistory = function (dimension, value) {
        var dimensionValues = this.getHistory(dimension);
        // ウィンドウ幅（圧縮単位）に応じた統計値履歴の圧縮を行う
        // 現在の設定だと、WEEKの場合は日単位、MONTHの場合は週単位で統計値履歴を圧縮する
        var range = getWindowRange(this.windowType);
        var otherRangeDimensionValues = dimensionValues.filter(function (d) { return d.k < range.from; });
        var currentRangeDimensionValues = dimensionValues.filter(function (d) { return range.from <= d.k && d.k < range.to; });
        var newValue = calculateAll(dimension, currentRangeDimensionValues, value);
        // 不要な統計値を削除
        var newDimensionValues = gc(dimension.type, [newValue].concat(otherRangeDimensionValues));
        // 後続で shortenId がある場合にはBlitzに送る処理が入っている
        // History の方は Blitz に送らないので、shortenId が含まれていたら削除する
        if (newValue.shortenId) {
            newDimensionValues.forEach(function (d) {
                if (d.shortenId) {
                    d.shortenId = undefined;
                }
            });
        }
        this.storageSet.dimensionHistoryStorage.setDimensionHistory(dimension, newDimensionValues);
    };
    SlidingWindowCalculator.prototype.update = function (dimension) {
        var oldValues = this.getHistory(dimension);
        var newValue = calculateAll(dimension, oldValues, null);
        // oldValues が shortenId を持っていない時には newValue.shortenId が入らない可能性があるので、ここでセットする
        if (!newValue.shortenId && dimension.shortenId) {
            newValue.shortenId = dimension.shortenId;
        }
        this.storageSet.dimensionStorage.setDimension(dimension.id, dimension.tw, newValue);
    };
    SlidingWindowCalculator.prototype.getHistory = function (dimension) {
        var values = this.storageSet.dimensionHistoryStorage.getDimensionHistory(dimension);
        // タイムウィンドウの範囲内の統計情報のみを返す
        var range = this.allWindowRange;
        return values.filter(function (d) { return range.from <= d.k && d.k < range.to; });
    };
    return SlidingWindowCalculator;
}());
export default SlidingWindowCalculator;
