import { toReduceKey } from './reduceKey';
var DAY = 24 * 60 * 60 * 1000;
var WeekWindow = /** @class */ (function () {
    function WeekWindow() {
    }
    WeekWindow.prototype.getRange = function (date) {
        date.setHours(0, 0, 0, 0);
        var dayOfWeek = date.getDay();
        // from: Sunday -> to: Saturday
        var from = date.getTime() - DAY * dayOfWeek;
        var to = from + DAY * 7;
        return { from: toReduceKey(from), to: toReduceKey(to) };
    };
    return WeekWindow;
}());
export { WeekWindow };
export default new WeekWindow();
