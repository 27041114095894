import { RenderFunction, RenderModule } from './types';
import { shouldRenderWhenNotRenderedYet, setAttributesToBlock, executeScript } from './utils';

export const render: RenderFunction<'html'> = (element, { html, script, blockAttributes, variationId }) => {
  element.innerHTML = html;
  setAttributesToBlock({ element, blockAttributes, elementAttributes: [] });
  if (script) executeScript({ script, variationId });
};

export const create = (): RenderModule<'html'> => {
  return {
    render,
    shouldRender: shouldRenderWhenNotRenderedYet,
  };
};
