type BlitzEventBlocksEventName = {
  view: 'blocks_view';
  pageView: 'blocks_page_view';
  click: 'blocks_message_click';
  apply: 'blocks_message_open';
  impression: 'blocks_impression';
  skippedAreaClick: 'blocks_skipped_message_click';
  skippedAreaImpression: 'blocks_skipped_impression';
  dynamicBlockPageView: 'blocks_dynamic_block_page_view';
};
// キーの名前がBlocksのイベント名、それに対応するvalueがBlitzに送るイベント名
const BLITZ_BLOCKS_EVENT_NAME_MAP: BlitzEventBlocksEventName = {
  // Blocksのページが閲覧されたときにマッチしたpageGroup分送るイベント
  // unanalysed event
  view: 'blocks_view',

  // Blocksのページが閲覧されたときに一度だけ送るイベント
  // プランごとの閲覧数を計算する時に使用することが目的
  // unanalysed event
  pageView: 'blocks_page_view',

  // Block の書き換えた要素がクリックされた時に飛ぶイベント
  // unanalysed event
  click: 'blocks_message_click',

  // Block の書き換えが行われた時に飛ぶイベント
  // unanalysed event
  apply: 'blocks_message_open',

  // Block の書き換えた要素がスクロールなどにより50%以上表示された時に飛ぶイベント
  // unanalysed event
  impression: 'blocks_impression',

  // unanalysed event
  skippedAreaClick: 'blocks_skipped_message_click',

  // unanalysed event
  skippedAreaImpression: 'blocks_skipped_impression',

  // unanalysed event
  dynamicBlockPageView: 'blocks_dynamic_block_page_view',
};

type EventName =
  | 'pageView'
  | 'view'
  | 'click'
  | 'apply'
  | 'impression'
  | 'skippedAreaClick'
  | 'skippedAreaImpression';

type EventNameForBlitz =
  | 'blocks_page_view'
  | 'blocks_message_click'
  | 'blocks_message_open'
  | 'blocks_impression'
  | 'blocks_skipped_message_click'
  | 'blocks_skipped_impression'
  | 'blocks_view';

type Event = {
  eventName: EventName;
  goalName?: string;
  conditionId?: string;
  patternId?: string;
  areaId?: string;
  variationId?: string;
  goalId?: string;
  hasControlForCondition?: boolean;
};
type PartsEvent = {
  type: 'impression' | 'click' | 'focus';
  trackingId: string;
  value?: number;
};

export { EventName, EventNameForBlitz, Event, PartsEvent, BLITZ_BLOCKS_EVENT_NAME_MAP };
