import { makeReduceKey } from '../window/reduceKey';
var LastStats = /** @class */ (function () {
    function LastStats() {
    }
    LastStats.prototype.dataType = function () {
        return 'any';
    };
    LastStats.prototype.initialValue = function () {
        return { v: undefined, k: 0 };
    };
    LastStats.prototype.calculateFromValue = function (oldValue, value) {
        var newValue = this.calculate(value);
        return newValue;
    };
    LastStats.prototype.calculateFromStats = function (oldValue, value) {
        var newValue = this.calculate(value.v);
        return newValue;
    };
    LastStats.prototype.gc = function (values) {
        // 最新の統計情報のみを残せばOK
        return values.length > 0 ? [values[0]] : [];
    };
    LastStats.prototype.calculate = function (value) {
        var newValue = {
            v: value,
            k: makeReduceKey(),
        };
        return newValue;
    };
    return LastStats;
}());
export default new LastStats();
