import { RenderFunction, RenderModule, ShouldRenderFunction } from './types';
import {
  setAttributesToBlock,
  createBlockAreaAttributes,
  shouldRenderWhenNotRenderedYet,
  appendCSSToElement,
  executeScript,
} from './utils';

export const render: RenderFunction<'outer-html' | 'blocks-element'> = (
  element,
  { areaId, html, script, style, blockAttributes, variationId },
) => {
  if (!html) return;
  /**
   * <div></div>.outerHtml = <p></p> で書き換えると、 <div></div> の ref 変わってしまう。
   * すると、 setAttributesToBlock が効かなくなるので、そうならないようにこうしてる。
   *
   * template で括らないと、消えてしまうタグがある (tr).
   */
  const fragment = document.createElement('template');
  fragment.innerHTML = html;
  /**
   * ie は template 未対応なので、|| fragment.firstElementChild で回避
   */
  const renderedElement = fragment.content?.firstElementChild || fragment.firstElementChild;
  if (!renderedElement) return;
  /**
   * outerhtml の場合は、allocate id が消えてしまうことがあるので、書き換え後にもつけてあげるようにする。
   */
  const blockAreaAttibutes = createBlockAreaAttributes({ areaId });
  setAttributesToBlock({
    element: renderedElement,
    blockAttributes: blockAttributes.concat(blockAreaAttibutes),
    elementAttributes: [],
  });
  if (style) appendCSSToElement(renderedElement, style);
  element.outerHTML = renderedElement.outerHTML;
  if (script) executeScript({ script, variationId });
};

export const shouldRender: ShouldRenderFunction<'outer-html' | 'blocks-element'> = (
  element,
  renderObject,
  option,
) => {
  if (option?.force) return true;
  return shouldRenderWhenNotRenderedYet(element, renderObject, option);
};

export const create = (): RenderModule<'outer-html' | 'blocks-element'> => {
  return {
    render,
    shouldRender,
  };
};
