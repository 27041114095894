import { FixedWindowCalculator } from '../window';
var AllTimeWindow = /** @class */ (function () {
    function AllTimeWindow(storageSet) {
        this.storageSet = storageSet;
    }
    AllTimeWindow.prototype.getCalculator = function () {
        return new FixedWindowCalculator(this.storageSet);
    };
    AllTimeWindow.prototype.getKey = function () {
        return '-';
    };
    return AllTimeWindow;
}());
export { AllTimeWindow };
