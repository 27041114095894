function uuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .split('')
    .map(c => {
      switch (c) {
        case 'x':
          return Math.floor(Math.random() * 16).toString(16);
        case 'y':
          return (Math.floor(Math.random() * 4) + 8).toString(16);
        default:
          return c;
      }
    })
    .join('');
}

export { uuid };
