export function createVariablesStorage({ storage, key, }) {
    const set = variables => {
        storage.setItem(key, JSON.stringify(variables));
    };
    const get = () => {
        try {
            const rawValue = storage.getItem(key);
            if (rawValue) {
                return JSON.parse(rawValue);
            }
        }
        catch (e) { }
        return {};
    };
    return {
        set,
        get,
    };
}
