import { ElementAttributesOptions } from './ElementAttributes';
import { Raw } from '../config_types';

/**
 * style属性
 * style属性は値に { stylePropertyKey: stylePropertyValue } 形式をオブジェクトを返す
 * 例)
 * {
 *   width: "100px",
 *   fontSize: "12px"
 * }
 */
export type ElementAttributeStyle = {
  key: 'style';
  // elementAttributes.styleが必ずオブジェクトという型定義(undefinedではないということ)
  value: NonNullable<NonNullable<Raw.Variation['elementAttributes']>['style']>;
};
export const elementAttributeStyle = ({
  variation,
}: ElementAttributesOptions): ElementAttributeStyle[] => {
  const elementAttributes = variation.elementAttributes;
  const styles = elementAttributes.style;
  if (!styles) {
    return [];
  }
  const styleProperyKeys = Object.keys(styles);
  const stylesLength = styleProperyKeys.length;
  if (stylesLength === 0) {
    return []; // 空のstyleなら何もしない
  }
  // 値がundefinedや空文字のプロパティを取り除いたstyleを作成する
  const stylesWithoutEmptyProperty: ElementAttributeStyle['value'] = {};
  for (let i = 0; i < stylesLength; i++) {
    const propertyKey = styleProperyKeys[i] as keyof typeof styles;
    const property = styles[propertyKey];
    // "0"がありえるため、厳密な判定をしている
    if (property !== undefined && property !== '') {
      stylesWithoutEmptyProperty[propertyKey] = property;
    }
  }
  return stylesWithoutEmptyProperty ? [{ key: 'style', value: stylesWithoutEmptyProperty }] : [];
};
