import { get } from './storage-util';

// debug用。ここでしか使わない。
const LOCAL_KEYS = {
  KRT_REWRITE_LOGGER_ENABLE: 'krt_rewrite_logger_enable',
};

function info(...args: any[]) {
  switch (args.length) {
    case 2: {
      const msg = args[0];
      const obj = args[1];
      window.console.log(msg, obj);
      break;
    }
    case 1: {
      const msg = args[0];
      window.console.log(msg);
      break;
    }
    default: {
      window.console.log(...args);
    }
  }
}

function createLogger() {
  const getFromLocal = get(window.localStorage);
  const isLoggerEnable = getFromLocal<boolean>(LOCAL_KEYS.KRT_REWRITE_LOGGER_ENABLE);
  return {
    info(...args: any[]) {
      if (!isLoggerEnable) return;
      info(...args);
    },
  };
}

const logger = createLogger();

export { LOCAL_KEYS, logger };
