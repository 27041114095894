import { toReduceKey } from './reduceKey';
var DAY = 24 * 60 * 60 * 1000;
var DayWindow = /** @class */ (function () {
    function DayWindow() {
    }
    DayWindow.prototype.getRange = function (date) {
        date.setHours(0, 0, 0, 0);
        var from = date.getTime();
        var to = from + DAY;
        return { from: toReduceKey(from), to: toReduceKey(to) };
    };
    return DayWindow;
}());
export { DayWindow };
export default new DayWindow();
