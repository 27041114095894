function sendByXHR(url, data) {
    var xhr = new XMLHttpRequest();
    // ---
    // generated by coffee-script 1.9.2
    xhr.open('POST', url);
    xhr.onerror = function () {
        // エラー処理
        window.console.error("[ingest.sendByXHR] error status: ".concat(xhr.status));
    };
    xhr.send(JSON.stringify(data));
}
function send(ingestUrl, data) {
    if (!window.navigator)
        return;
    try {
        sendByXHR(ingestUrl, data);
    }
    catch (e) {
        window.console.error(e);
    }
}
export { send };
