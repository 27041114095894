function createVariableQueryResolver(context, variableQuery) {
    if (variableQuery.resolver === 'number' ||
        variableQuery.resolver === 'string' ||
        variableQuery.resolver === 'boolean') {
        return {
            async resolve() {
                return {
                    [variableQuery.name]: variableQuery.query.value,
                };
            },
            async resolvePreview() {
                return {
                    [variableQuery.name]: variableQuery.query.value,
                };
            },
            getInitial() {
                return {
                    [variableQuery.name]: variableQuery.query.value,
                };
            },
        };
    }
    else {
        console.warn(`Unknown resolver: ${variableQuery.resolver}`);
        return {
            resolve: async () => ({}),
            resolvePreview: async () => ({}),
            getInitial: () => ({}),
        };
    }
}
export function createStaticVariablesQueryResolver(context, variablesQuery) {
    const resolvers = variablesQuery.map(variableQuery => createVariableQueryResolver(context, variableQuery));
    function mergeResults(arr) {
        return arr.reduce((acc, result) => ({ ...acc, ...result }), {});
    }
    async function resolve(resolvedVariables) {
        const results = await Promise.all(resolvers.map(resolver => resolver.resolve(resolvedVariables)));
        return mergeResults(results);
    }
    async function resolvePreview() {
        const results = await Promise.all(resolvers.map(resolver => resolver.resolvePreview()));
        return mergeResults(results);
    }
    function getInitial() {
        const results = resolvers.map(resolver => resolver.getInitial());
        return mergeResults(results);
    }
    return {
        resolve,
        resolvePreview,
        getInitial,
    };
}
