import { isNumberValue } from '../guard';
import { makeReduceKey } from '../window/reduceKey';
var AverageStats = /** @class */ (function () {
    function AverageStats() {
    }
    AverageStats.prototype.dataType = function () {
        return 'number';
    };
    AverageStats.prototype.initialValue = function () {
        return { v: 0, k: 0 };
    };
    AverageStats.prototype.calculateFromValue = function (oldValue, value) {
        oldValue.s = oldValue.s || 0;
        oldValue.c = oldValue.c || 0;
        value = isNumberValue(value) ? value : 0;
        var newValue = this.calculate(oldValue.s, oldValue.c, value);
        return newValue;
    };
    AverageStats.prototype.calculateFromStats = function (oldValue, value) {
        oldValue.s = oldValue.s || 0;
        oldValue.c = oldValue.c || 0;
        value.s = value.s || 0;
        value.c = value.c || 0;
        var newValue = this.calculate(oldValue.s, oldValue.c, value.s, value.c);
        return newValue;
    };
    AverageStats.prototype.gc = function (values) {
        return values;
    };
    AverageStats.prototype.calculate = function (oldSum, oldCnt, sum, cnt) {
        if (cnt === void 0) { cnt = 1; }
        var newValue = { v: 0, k: makeReduceKey() };
        newValue.s = oldSum + sum;
        newValue.c = oldCnt + cnt;
        newValue.v = newValue.s / newValue.c;
        return newValue;
    };
    return AverageStats;
}());
export default new AverageStats();
