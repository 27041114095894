import { edgeDimensionTypes as t } from 'rewrite-common';
var RegularExpression = /** @class */ (function () {
    function RegularExpression(value, compare, isRegex) {
        this.compare = compare;
        this.regExp = this.makeRegExp(value, compare, isRegex != null ? isRegex : false);
    }
    Object.defineProperty(RegularExpression.prototype, "isNot", {
        get: function () {
            switch (this.compare) {
                case t.CompareType.NE:
                case t.CompareType.NMF:
                case t.CompareType.NMP:
                case t.CompareType.NMB:
                    return true;
                default:
                    return false;
            }
        },
        enumerable: false,
        configurable: true
    });
    RegularExpression.escape = function (str) {
        // eslint-disable-next-line no-useless-escape
        return str.replace(/[.*+?^=!:${}()|[\]\/\\]/g, '\\$&');
    };
    RegularExpression.prototype.test = function (value) {
        var ret = this.regExp.test(value);
        return this.isNot ? !ret : ret;
    };
    RegularExpression.prototype.makeRegExp = function (value, compare, isRegex) {
        var escapedValue = isRegex ? value : RegularExpression.escape(value);
        var regExpStr = this.makeRegExpString(escapedValue, compare);
        return new RegExp(regExpStr);
    };
    RegularExpression.prototype.makeRegExpString = function (value, compare) {
        switch (compare) {
            case t.CompareType.MF:
            case t.CompareType.NMF:
                return "^".concat(value);
            case t.CompareType.MP:
            case t.CompareType.NMP:
                return value;
            case t.CompareType.MB:
            case t.CompareType.NMB:
                return "".concat(value, "$");
            case t.CompareType.EQ:
            case t.CompareType.NE:
                return "^".concat(value, "$");
            default:
                throw new Error("Invalid compareType: ".concat(compare));
        }
    };
    return RegularExpression;
}());
export default RegularExpression;
