import { get, set } from './utils/storage-util';
import { pack, converter } from './converter';
import { send } from './sender';
import { uuid } from './utils/uuid';
import { isPlainObject } from './utils/typecheck';
var LOCAL_KEY = {
    KRT_VID_KEY: 'krt___ingest.vis', // trackerとは別. 同期ingest固有のkey
};
var TRACKER_VERSION = '0.6';
var Ingest = /** @class */ (function () {
    function Ingest(apiKey, options) {
        this.apiKey = apiKey;
        this.pvId = uuid();
        this.originalPvId = this.pvId;
        this.ingestUrl = options.ingestUrl || 'https://t.karte.io/ingest';
        // load local storage
        var getFromLocal = get(window.localStorage);
        var setToLocal = set(window.localStorage);
        this.visitorId = getFromLocal(LOCAL_KEY.KRT_VID_KEY) || uuid();
        setToLocal(LOCAL_KEY.KRT_VID_KEY, this.visitorId);
    }
    Ingest.prototype.track = function (events) {
        var data = this._createReqData(events);
        send(this.ingestUrl, data);
    };
    // 指定した変数名でcustom eventsが登録されてあれば送る
    Ingest.prototype.trackGlobalCustomEvents = function () {
        // @ts-ignore
        if (typeof krt_edge_custom_events === 'undefined' || !Array.isArray(krt_edge_custom_events))
            return;
        // @ts-ignore
        var customEvents = krt_edge_custom_events;
        var ingestEvents = customEvents
            .filter(function (e) {
            if (!isPlainObject(e))
                return false;
            if (!e.ingest)
                return false;
            if (!e.event_name)
                return false;
            if (e.values != null && !isPlainObject(e.values))
                return false;
            return true;
        })
            // @ts-ignore
            .map(function (e) {
            var values = isPlainObject(e.values) ? e.values : {};
            return {
                event_name: e.event_name,
                values: values,
            };
        });
        if (ingestEvents.length === 0)
            return;
        this.track(ingestEvents);
    };
    Ingest.prototype._createReqData = function (events) {
        var d = {
            // access: this.access,
            // is_login: this.logined,
            api_key: this.apiKey,
            keys: this._createKeys(),
            events: events,
            tracker_version: TRACKER_VERSION,
            // enceds,   // v1.11.2で複数対応した
            // enced: enceds[0] // 互換性のために残す
        };
        d = converter(d, 'date');
        // @ts-ignore
        d = pack(d);
        return {
            d: JSON.stringify(d),
            v: TRACKER_VERSION,
            encoded: false,
        };
    };
    Ingest.prototype._createKeys = function () {
        // cookieから取得するものは一旦同期ingestではサポートしない
        // 必要なら非同期タグで連携する
        var keys = {
            api_key: this.apiKey,
            visitor_id: this.visitorId,
            // path: this.access.uri.path,
            // host: this.access.uri.host,
            pv_id: this.pvId,
            original_pv_id: this.originalPvId,
            // live_session_id: this._getLiveSessionId(),
        };
        // MEMO: identifyでuser_idを送って成功した場合はセットしておいてもいいかも
        // if (((ref = this._user) != null ? ref.user_id : void 0) != null) {
        //   keys.user_id = this._user.user_id;
        // }
        return keys;
    };
    return Ingest;
}());
export default Ingest;
