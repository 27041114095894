import { getStorage } from './Storage';
var VisitTimeStorage = /** @class */ (function () {
    function VisitTimeStorage(storageType) {
        this.storage = getStorage(storageType, 'krtvt');
    }
    VisitTimeStorage.prototype.getStorageKey = function () {
        return 'ts';
    };
    VisitTimeStorage.prototype.getVisitTime = function () {
        var ts = this.storage.get(this.getStorageKey());
        return ts ? ts : 0;
    };
    VisitTimeStorage.prototype.setVisitTime = function (ts) {
        this.storage.set(this.getStorageKey(), ts);
    };
    return VisitTimeStorage;
}());
export { VisitTimeStorage };
export function getVisitTimeStorage(storageType) {
    return new VisitTimeStorage(storageType);
}
