var Tracker = /** @class */ (function () {
    function Tracker(calculator) {
        this.calculator = calculator;
    }
    Tracker.prototype.track = function (eventName, values) {
        var _a;
        // datetimeを追加 & eventNameをmerge
        var value = {};
        Object.keys(values).forEach(function (k) {
            value[k] = values[k];
        });
        value['date_'] = Date.now();
        var values_ = (_a = {},
            _a[eventName] = value,
            _a);
        this.calculator.update(values_);
        // todo: のちのちkarte本体のtrackも呼ぶ
    };
    return Tracker;
}());
export default Tracker;
