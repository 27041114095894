import { calculateAll } from '../stats';
var PreviousWindowCalculator = /** @class */ (function () {
    function PreviousWindowCalculator(storageSet) {
        this.storageSet = storageSet;
    }
    PreviousWindowCalculator.prototype.calculate = function (dimension, value) {
        var values = this.storageSet.dimensionHistoryStorage.getDimensionHistory(dimension);
        var newValue = calculateAll(dimension, values, value);
        // 後続で shortenId がある場合にはBlitzに送る処理が入っている
        // History の方は Blitz に送らないので、shortenId が含まれていたら削除する
        if (newValue.shortenId) {
            newValue.shortenId = undefined;
        }
        this.storageSet.dimensionHistoryStorage.setDimensionHistory(dimension, [newValue]);
    };
    PreviousWindowCalculator.prototype.change = function (tw, newKey) {
        var oldKey = this.storageSet.timeWindowKeyStorage.getTimeWindowKey(tw);
        if (oldKey && oldKey !== newKey) {
            this.moveToDimensionFromHistory(tw);
        }
        this.storageSet.timeWindowKeyStorage.setTimeWindowKey(tw, newKey);
        return oldKey !== newKey;
    };
    PreviousWindowCalculator.prototype.moveToDimensionFromHistory = function (tw) {
        var _a = this.storageSet, dimensionStorage = _a.dimensionStorage, dimensionHistoryStorage = _a.dimensionHistoryStorage;
        dimensionStorage.clearDimensionByTw(tw);
        this.storageSet.dimensionHistoryStorage
            .getDimensionHistoryWithKey()
            .filter(function (v) { return v.tw === tw; })
            .map(function (v) {
            return { id: v.id, tw: v.tw, value: v.values[0] };
        })
            .forEach(function (v) {
            var key = dimensionHistoryStorage.getDimensionHistoryKey(v.id, tw);
            dimensionHistoryStorage.removeDimensionHistoryFromKey(key);
            dimensionStorage.setDimension(v.id, tw, v.value);
        });
    };
    return PreviousWindowCalculator;
}());
export default PreviousWindowCalculator;
