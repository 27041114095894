import { isNumberValue } from '../guard';
import { makeReduceKey } from '../window/reduceKey';
var MaxStats = /** @class */ (function () {
    function MaxStats() {
    }
    MaxStats.prototype.dataType = function () {
        return 'number';
    };
    MaxStats.prototype.initialValue = function () {
        return { v: undefined, k: 0 };
    };
    MaxStats.prototype.calculateFromValue = function (oldValue, value) {
        var newValue = this.calculate(oldValue, value);
        return newValue;
    };
    MaxStats.prototype.calculateFromStats = function (oldValue, value) {
        var newValue = this.calculate(oldValue, value.v);
        return newValue;
    };
    MaxStats.prototype.gc = function (values) {
        // 最初に現れる最大値のインデックスを求める
        var ret = values.reduce(function (acc, v, i) {
            if (isNumberValue(acc.v) && isNumberValue(v.v)) {
                return v.v > acc.v ? { v: v.v, i: i } : acc;
            }
            else if (isNumberValue(v.v)) {
                return { v: v.v, i: i };
            }
            else {
                return acc;
            }
        }, { v: undefined, i: -1 });
        // 最新の統計情報から最大値を含む統計情報までの配列を切り出す
        return values.splice(0, ret.i + 1);
    };
    MaxStats.prototype.calculate = function (oldValue, value) {
        if (!isNumberValue(value)) {
            return oldValue;
        }
        var newValue = { v: undefined, k: makeReduceKey() };
        if (!isNumberValue(oldValue.v)) {
            newValue.v = value;
            return newValue;
        }
        else if (oldValue.v < value) {
            newValue.v = value;
            return newValue;
        }
        else {
            return oldValue;
        }
    };
    return MaxStats;
}());
export default new MaxStats();
