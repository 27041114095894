const DURATION = 500; // ms
const LIMIT = 50; // THRESHOLD / INTERVAL [count/ms] を超えたら setTimeout

interface ObserveMutationCallbacks {
  /**
   * @param disconnect disconnect observer
   * @param lazyReconnect reconnect observer lazily
   */
  onDomChanged: (disconnect: () => void, lazyReconnect: () => void) => void;
  onUrlChanged: (disconnect: () => void) => void;
}

type ovserveMutationOptions = {
  ignoreHashOnUrl?: boolean;
};

function locationUrl(ignoreHash: boolean) {
  const baseUrl = location.protocol + '//' + location.host + location.pathname + location.search;
  if (ignoreHash) {
    return baseUrl;
  }
  return baseUrl + location.hash;
}

function observeMutation(
  target: Node,
  callbacks: ObserveMutationCallbacks,
  options?: ovserveMutationOptions,
): void {
  const rateLimiter = new RateLimiter({ limit: LIMIT, duration: DURATION });
  const config: MutationObserverInit = {
    attributes: true,
    childList: true,
    characterData: true,
    subtree: true,
    attributeOldValue: true,
  };
  const ignoreHash = options?.ignoreHashOnUrl || false;
  let previousUrl = locationUrl(ignoreHash);
  /**
   * disconnect済みなら何もしないことを保証するためのラップ処理
   * Safariでdisconnectした後にもキューに溜まったMutationsでcallback関数が呼ばれることがあるため、isStopのフラグで確実に止める実装
   * https://github.com/plaidev/karte-io-systems/issues/33932
   */
  type MutationObserverWrapper = {
    // trueなら停止中なので、observerのcallbackはすぐreturnさせる
    shouldStop(): boolean;
    observe(target: Node, options?: MutationObserverInit): void;
    disconnect(): void;
  };
  /**
   * MutationObserverをラップしたMutationObserverWrapperを作って返す
   * callbackの第２引数には、observerの代わりにobserverWrapperを渡す
   * @param callback
   */
  const createMutationObserverWrapper = (
    callback: (mutations: MutationRecord[], observerWrapper: MutationObserverWrapper) => void,
  ): MutationObserverWrapper => {
    let isStopped = false;
    const observer = new MutationObserver((mutations: MutationRecord[]) => {
      callback(mutations, observerWrapper);
    });
    const observerWrapper: MutationObserverWrapper = {
      shouldStop() {
        return isStopped;
      },
      observe(target: Node, options?: MutationObserverInit) {
        if (observer) {
          isStopped = false;
          observer.observe(target, options);
        }
      },
      disconnect() {
        if (observer) {
          observer.disconnect();
          isStopped = true;
        }
      },
    };
    return observerWrapper;
  };
  const observer = createMutationObserverWrapper((mutations, observerWrapper) => {
    // disconnectした瞬間からキューに積まれているcallbackの呼び出しを無視するため
    // Note: disconnect中の変更は単純に無視されるので破棄される
    if (observerWrapper.shouldStop()) {
      return;
    }
    const nextUrl = locationUrl(ignoreHash);
    if (previousUrl !== nextUrl) {
      // onUrlChangedの中で書き換えが起きると、previousUrlが古いまま呼ばれることがあるため、先に変更
      // https://github.com/plaidev/karte-io-systems/issues/33932
      previousUrl = nextUrl;
      callbacks.onUrlChanged(() => {
        observerWrapper.disconnect();
      });
      return;
    }
    callbacks.onDomChanged(
      () => {
        // disconnect
        observerWrapper.disconnect();
      },
      () => {
        // lazyReconnect
        // prevent double connections
        observerWrapper.disconnect();
        // care event loop not to loop infinitely for some reason.
        if (rateLimiter.isBurst()) setTimeout(() => observerWrapper.observe(target, config), 0);
        else observerWrapper.observe(target, config);
      },
    );
  });
  observer.observe(target, config);
}

class RateLimiter {
  private readonly limit: number;
  private readonly duration: number;
  private counter: number[];

  constructor(args: { limit: number; duration: number }) {
    this.limit = args.limit;
    this.duration = args.duration;
    this.counter = [];
  }

  isBurst(): boolean {
    if (this.counter.length > this.limit) return true;
    this.counter.push(0);
    setTimeout(() => {
      this.counter.pop();
    }, this.duration);
    return false;
  }
}

export { observeMutation, RateLimiter };
