import { Raw } from './config_types';
import { includes } from './polyfill/includes';

// returns an integer in [0,100)
function parseUserIdToInt(userId: string): number {
  if (userId.length === 0) return 0;
  let hash = 0;
  let i = 0;
  for (i = 0; i < userId.length; i++) {
    hash = (hash << 5) - hash + userId.charCodeAt(i);
    hash |= 0;
  }
  return Math.abs(hash) % 100;
}

// rate（%）の分だけを通す
function isSampled(userId: string, rate: number): boolean {
  const hashNum = parseUserIdToInt(userId);
  if (hashNum <= rate) {
    return true;
  }
  return false;
}

function isUserAgentInWhiteList(userAgentWhiteList: readonly string[]): boolean {
  if (userAgentWhiteList.length === 0) {
    return true;
  }
  return includes(userAgentWhiteList, navigator.userAgent);
}

function isBlocked(uid: string, { isEnable, userAgentWhiteList, rate }: Raw.Sampling): boolean {
  if (!isEnable) {
    return false;
  }
  if (isUserAgentInWhiteList(userAgentWhiteList) && isSampled(uid, rate)) {
    return false;
  }
  return true;
}

export { parseUserIdToInt, isSampled, isUserAgentInWhiteList, isBlocked };
