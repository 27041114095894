var PERIOD = 2 * 365 * 24 * 60 * 60 * 1000; // 2年. trackerと合わせた
var get = function (storage) {
    return function (key) {
        try {
            var obj = storage.getItem(key);
            if (!obj) {
                return undefined;
            }
            var json = JSON.parse(obj);
            if (json.expire) {
                if (json.last + PERIOD <= new Date().getTime()) {
                    storage.removeItem(key);
                    return undefined;
                }
            }
            json.last = new Date().getTime();
            storage.setItem(key, JSON.stringify(json));
            return json.val;
        }
        catch (ex) {
            return undefined;
        }
    };
};
var set = function (storage) {
    return function (key, value, expire) {
        if (expire === void 0) { expire = false; }
        var obj = {
            expire: expire,
            last: new Date().getTime(),
            val: value,
        };
        try {
            storage.setItem(key, JSON.stringify(obj));
            return obj.val;
        }
        catch (ex) {
            return undefined;
        }
    };
};
export { get, set };
