function getUrl() {
    // hashの変更は検知しない
    return location.origin + location.pathname + location.search;
}
function observeMutation(target, callbacks) {
    var config = {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true,
    };
    var previousUrl = getUrl();
    var observer = new MutationObserver(function (mutations, observer) {
        var nextUrl = getUrl();
        if (previousUrl !== nextUrl) {
            callbacks.onUrlChanged(function () {
                observer.disconnect();
            });
            previousUrl = nextUrl;
            return;
        }
    });
    observer.observe(target, config);
}
export { observeMutation };
