import { parse } from './utils/uaparser';
var pickUrlFields = function (url) {
    return {
        href: url.href,
        host: url.host,
        hostname: url.hostname,
        origin: url.origin,
        pathname: url.pathname,
        hash: url.hash.replace(/^#/, ''),
        port: url.port,
        protocol: url.protocol,
        search: url.search.replace(/^\?/, ''),
    };
};
var createUserAgentFields = function (userAgent) {
    var ua = parse(userAgent);
    return {
        full: ua.full,
        brand: ua.brand,
        version: ua.version,
        platform: ua.platform,
    };
};
var createAccessFields = function (userAgent, screenWidth, screenHeight) {
    return {
        'user-agent': createUserAgentFields(userAgent),
        screen: {
            width: screenWidth,
            height: screenHeight,
        },
    };
};
export default (function (tracker) {
    var values = {};
    values.location = pickUrlFields(window.location);
    if (document.referrer) {
        var referrer = new URL(document.referrer);
        values.referrer = pickUrlFields(referrer);
        if (window.location.origin !== referrer.origin) {
            values.landing_referrer = pickUrlFields(referrer);
            values.landing_location = pickUrlFields(window.location);
        }
    }
    values.access = createAccessFields(navigator.userAgent, screen.width, screen.height);
    // pageview event
    tracker.track('page_view', values);
});
