var _a;
import { edgeDimensionTypes as t } from 'rewrite-common';
import avg from './avg';
import checkDataType from './checkDataType';
import cnt from './cnt';
import first from './first';
import last from './last';
import match from './match';
import max from './max';
import min from './min';
import prev from './prev';
import sum from './sum';
import partial_match from './partial_match';
var modules = (_a = {},
    _a[t.StatsType.SUM] = sum,
    _a[t.StatsType.AVG] = avg,
    _a[t.StatsType.MIN] = min,
    _a[t.StatsType.MAX] = max,
    _a[t.StatsType.CNT] = cnt,
    _a[t.StatsType.FIRST] = first,
    _a[t.StatsType.LAST] = last,
    _a[t.StatsType.PREV] = prev,
    _a[t.StatsType.MATCH] = match,
    _a[t.StatsType.PARTIAL_MATCH] = partial_match,
    _a);
export function calculate(statsType, oldValue, value, path, option) {
    var module = modules[statsType];
    // TODO
    checkDataType(module.dataType(), value, path);
    return module.calculateFromValue(oldValue, value, option);
}
export function calculateAll(dimension, oldValues, value) {
    var type = dimension.type, path = dimension.path, option = dimension.option;
    var stats = modules[type];
    if (value != null) {
        checkDataType(stats.dataType(), value, path);
    }
    var newValue = oldValues.reduceRight(function (acc, v) { return stats.calculateFromStats(acc, v, option === null ? undefined : option); }, stats.initialValue());
    return value != null
        ? stats.calculateFromValue(newValue, value, option === null ? undefined : option)
        : newValue;
}
export function gc(statsType, values) {
    var stats = modules[statsType];
    return stats.gc(values);
}
