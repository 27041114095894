import { edgeDimensionTypes as t } from 'rewrite-common';
import LocalStorage from './LocalStorage';
import MemoryStorage from './MemoryStorage';
export function getStorage(storageType, storageKeyPrefix) {
    switch (storageType) {
        case t.StorageType.MEMORY:
            return new MemoryStorage();
        case t.StorageType.LOCAL_STORAGE:
            return new LocalStorage(storageKeyPrefix);
        default:
            break;
    }
    return new MemoryStorage();
}
