import { RenderFunction, ShouldRenderFunction, RenderModule } from './types';
import { setAttributesToBlock, createElementAttributes, findParentATag } from './utils';
import { wrapElement } from '../dom/wrapElement';

export function rewriteAHref(aTag: Element, aHref: string): void {
  // diff check
  if (aTag.getAttribute('href') === aHref) return;
  aTag.setAttribute('href', aHref);
}

export function rewriteImage(img: Element, imgUrl: string): void {
  if (img.tagName === 'IMG') {
    // diff check
    if (img.getAttribute('src') !== imgUrl) {
      img.removeAttribute('src');
      img.setAttribute('src', imgUrl);
    }
    if (img.getAttribute('srcset') !== imgUrl) {
      img.setAttribute('srcset', imgUrl);
    }
    img.removeAttribute('alt');
  } else {
    const backgroundImage = `url("${imgUrl}")`;
    const style = (img as HTMLElement).style;

    // diff check
    if (style.backgroundImage === backgroundImage) return;

    (img as HTMLElement).style.backgroundImage = `url("${imgUrl}")`;
  }
}

export const shouldRender: ShouldRenderFunction<'image'> = (): boolean => {
  return true;
};

export const render: RenderFunction<'image'> = (
  img,
  { imgUrl, aHref, blockAttributes, rawElementAttributes },
) => {
  const elementAttributes = createElementAttributes(img, rawElementAttributes);
  rewriteImage(img, imgUrl);
  if (aHref) {
    const aTag = findParentATag(img);
    // 親にaタグがある場合は、aタグを書き換え
    if (aTag) {
      setAttributesToBlock({
        element: aTag,
        blockAttributes,
        elementAttributes: [],
      });
      rewriteAHref(aTag, aHref);
    } else {
      // 親のaタグがない場合は、aタグを作成してimgを囲む
      const newATag = document.createElement('a');
      setAttributesToBlock({
        element: newATag,
        blockAttributes,
        elementAttributes: [],
      });
      rewriteAHref(newATag, aHref);
      wrapElement(img, newATag);
    }
    // 親であるAを書き換える場合も、elementAttributes自体は指定した要素であるimgに対して設定する
    setAttributesToBlock({
      element: img,
      blockAttributes: [],
      elementAttributes,
    });
  } else {
    setAttributesToBlock({ element: img, blockAttributes, elementAttributes });
  }
};

export const create = (): RenderModule<'image'> => {
  return {
    render,
    shouldRender,
  };
};
