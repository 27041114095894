const JS_IDENTIFIER = '[a-zA-Z_$][0-9a-zA-Z_$]*';
const STRING_LITERAL_REGEXP = `'${JS_IDENTIFIER}'|"${JS_IDENTIFIER}"|\`${JS_IDENTIFIER}\``;
const SPACES = '\\s*';
const BLOCK_API_ROOT_IDENTIFIER = 'krtBlock';
const BLOCK_API_SET_VAL_IDENTIFIER = 'setVal';
export function extractBlockAPIVariable(script) {
    const variableNames = Array.from(script.matchAll(new RegExp(`${BLOCK_API_ROOT_IDENTIFIER}${SPACES}\\.${SPACES}${BLOCK_API_SET_VAL_IDENTIFIER}\\(${SPACES}(${STRING_LITERAL_REGEXP})${SPACES},`, 'g')))
        .map(match => match[1])
        .map(raw => raw.slice(1, -1));
    return variableNames;
}
