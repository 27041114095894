import { logger } from './logger';

/**
 * リトライ処理を行う。実行に失敗した場合は、指数バックオフを行い、最大リトライ回数を超えるまでリトライを行う。
 * @param fn リトライ対象の関数。ログ出力に用いられるので無名関数は非推奨。
 * @param checker リトライ成功判定を行う関数
 * @param maxRetries 最大リトライ回数
 * @param delayMs リトライ間隔の初期値
 * @param delayMaxMs リトライ間隔の最大値
 * @returns リトライ成功時の関数の戻り値
 */
export const exponentialBackoff = async <T>(
  fn: (...args: any[]) => T | Promise<T>,
  checker: (result: T) => boolean,
  maxRetries: number = 5,
  delayMs: number = 200,
  delayMaxMs: number = 1000,
): Promise<T | undefined> => {
  for (let retries = 0; retries < maxRetries; retries++) {
    const result = await fn();
    if (checker(result)) {
      return result;
    }
    logger.info(`failed to call ${fn.name}. retrying ${retries + 1}/${maxRetries}.`);
    const waitTime = Math.min(
      delayMs * 2 ** retries,
      // テスト実行時は待ち時間を短くする
      process.env.NODE_ENV === 'test' ? 10 : delayMaxMs,
    );
    await new Promise(resolve => setTimeout(resolve, waitTime));
  }
  return undefined;
};
