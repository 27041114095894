// config
import envData from '~data/data';
// dimension
import { dimensionInit, ingestInit } from 'rewrite-dimension-lib';
// rewrite and analytics
import { rewriteMain } from './app';
import { existsSkippingQuery, preventDoubleExecution } from './modules/debugger-utility';
import { logger } from './modules/logger';
import { isOptoutUser } from './modules/optout';
import {
  parsePublicPreviewClientURL,
  runPublicPreviewClient,
} from './modules/publicPreview/publicPreviewLoader';

function main() {
  preventDoubleExecution();
  if (isOptoutUser()) return;
  if (existsSkippingQuery()) {
    logger.info('builder.js is skipped, since skipping query exists.');
    return;
  }

  if (envData.dimensionConfig) {
    dimensionInit(envData.dimensionConfig);
  }

  if (envData.ingestConfig) {
    ingestInit(envData.ingestConfig);
  }

  // ?krt_public_previewがあるなら、元のrewriteはせずにpublic_preview_clientを読み込む
  const previewClientScriptURL = parsePublicPreviewClientURL(location.href);
  if (previewClientScriptURL) {
    runPublicPreviewClient(previewClientScriptURL);
  } else {
    rewriteMain(envData.krt_rewrite_config, envData.deploymentId);
  }
}

try {
  main();
} catch (e) {
  window.console.error(e);
}
