import { calculate } from '../stats';
var FixedWindowCalculator = /** @class */ (function () {
    function FixedWindowCalculator(storageSet) {
        this.storageSet = storageSet;
    }
    FixedWindowCalculator.prototype.calculate = function (dimension, value) {
        var oldValue = this.storageSet.dimensionStorage.getDimension(dimension.id);
        var newValue = calculate(dimension.type, oldValue, value, dimension.path, dimension.option === null ? undefined : dimension.option);
        // oldValues が shortenId を持っていない時には newValue.shortenId が入らない可能性があるので、ここでセットする
        if (!newValue.shortenId && dimension.shortenId) {
            newValue.shortenId = dimension.shortenId;
        }
        this.storageSet.dimensionStorage.setDimension(dimension.id, dimension.tw, newValue);
    };
    FixedWindowCalculator.prototype.change = function (tw, newKey) {
        var oldKey = this.storageSet.timeWindowKeyStorage.getTimeWindowKey(tw);
        if (oldKey && oldKey !== newKey) {
            this.storageSet.dimensionStorage.clearDimensionByTw(tw);
        }
        this.storageSet.timeWindowKeyStorage.setTimeWindowKey(tw, newKey);
        return oldKey !== newKey;
    };
    return FixedWindowCalculator;
}());
export default FixedWindowCalculator;
