import { edgeDimensionTypes as t } from 'rewrite-common';
import { getVisitTimeStorage } from '../storage/visitTimeStorage';
import { FixedWindowCalculator } from '../window';
var SessionTimeWindow = /** @class */ (function () {
    function SessionTimeWindow(storageSet) {
        this.storageSet = storageSet;
        this.visitTimeStorage = getVisitTimeStorage(storageSet.storageType);
    }
    SessionTimeWindow.prototype.getCalculator = function () {
        return new FixedWindowCalculator(this.storageSet);
    };
    SessionTimeWindow.prototype.getKey = function () {
        // 前回来訪からの時間差を計算
        var visTs = this.visitTimeStorage.getVisitTime();
        var nowTs = Date.now();
        var diff = nowTs - visTs;
        // 最終来訪時刻を更新
        this.visitTimeStorage.setVisitTime(nowTs);
        // セッション境界の判定
        if (SessionTimeWindow.INTERVAL > diff) {
            // 最終来訪から30min未満
            return this.storageSet.timeWindowKeyStorage.getTimeWindowKey(t.TimeWindowType.SESSION);
        }
        else {
            // 最終来訪から30min以上経過
            return String(nowTs);
        }
    };
    SessionTimeWindow.INTERVAL = 30 * 60 * 1000;
    return SessionTimeWindow;
}());
export { SessionTimeWindow };
