import { RenderModule, ShouldRenderFunctionOption } from './types';
import { create as afterHtmlModule } from './after-html';
import { create as beforeHtmlModule } from './before-html';
import { create as outerHtmlModule } from './outer-html';
import { create as appendHtmlModule } from './append-html';
import { create as htmlModule } from './html';
import { create as imageModule } from './image';
import { create as textModule } from './text';
import { create as originalModule } from './original';
import { create as removeModule } from './remove';
import { Builder, Raw } from '../config_types';
import {
  separateHtmlAndStyleForOuterHtml,
  createBlockAttributes,
  createBlockAreaAttributes,
  createBlockAreaSelector,
  addEventListenerToDom,
  setAttributesToBlock,
  BLOCK_AREA_ATTR,
} from './utils';

/**
 * これが使われます
 *
 * block area id がついてる element を書き換える
 */
export type RewriteOption = ShouldRenderFunctionOption;
function rewrite(variation: Raw.Variation, option?: RewriteOption) {
  const element = document.querySelector(createBlockAreaSelector(variation.areaId));
  if (!element) return;
  const object = createRenderObject(variation);
  render(element, object, option);
}

/**
 * rendering する対象につける attribute.
 * この attr がついてる element を書き換えることにする
 */
function allocateIdToBlockArea(variation: Raw.Variation) {
  const element = document.querySelector(variation.cssSelector);
  if (!element) return;
  if (element.hasAttribute(BLOCK_AREA_ATTR)) return;
  const blockAttributes = createBlockAreaAttributes(variation);
  setAttributesToBlock({ element, blockAttributes, elementAttributes: [] });
}

export const SKIPPED_AREA_IDS_ATTR = 'data-krt-blocks-skipped-area-ids';

/**
 * rendering する対象につける attribute.
 * この attr がついてる element は書き換えられていないこととする
 *
 * NOTE: allocatedIdToBlockArea が実行されている前提
 */
function allocateSkippedIdToBlockArea({
  matchedPageGroups,
  matchedVariations,
}: {
  matchedPageGroups: Raw.PageGroup[];
  matchedVariations: readonly Raw.Variation[];
}): void {
  // 書き換えされたオリジナルブロックにスキップ属性を付与
  matchedVariations.forEach(variation => {
    const areaElement = document.querySelector<HTMLElement>(
      createBlockAreaSelector(variation.areaId),
    );
    if (!areaElement || variation.type !== 'original') return;
    let ids = areaElement.getAttribute(SKIPPED_AREA_IDS_ATTR);
    if (!ids) {
      ids = '';
      areaElement.setAttribute(
        SKIPPED_AREA_IDS_ATTR,
        `${ids},${variation.areaId}`.replace(/^,/, ''),
      );
    }
  });

  // 書き換えされない(他のブロックに阻害されたまたは配信条件にマッチしない)DOM要素にスキップ属性を付与
  let allVariations = [] as Raw.Variation[];
  matchedPageGroups.forEach(matchedPageGroup => {
    const { conditions: allConditions } = matchedPageGroup;
    allConditions.forEach(condition => {
      const { patterns: allPatterns } = condition;
      allPatterns.forEach(pattern => {
        const { variations } = pattern;
        allVariations = [...allVariations, ...variations];
      });
    });
  });

  allVariations.forEach(variation => {
    const element = document.querySelector<HTMLElement>(variation.cssSelector);
    if (element && element.dataset.krtBlocksArea !== variation.areaId) {
      let ids = element.getAttribute(SKIPPED_AREA_IDS_ATTR);
      if (!ids) {
        ids = '';
      }
      if (!ids.includes(variation.areaId)) {
        element.setAttribute(SKIPPED_AREA_IDS_ATTR, `${ids},${variation.areaId}`.replace(/^,/, ''));
      }
    }
  });
}

const EVENT_LISTENED_ATTR = 'data-krt-blocks-event-listened';
/**
 * これが使われます
 */
function addEventListenersToDoms({
  elementId,
  cssSelector,
  onClick,
}: {
  elementId: string;
  cssSelector: string;
  onClick: EventListenerOrEventListenerObject;
}) {
  const element = document.querySelector(cssSelector);
  if (!element) return;

  let ids = element.getAttribute(EVENT_LISTENED_ATTR);
  if (!ids) {
    ids = '';
  }
  if (!ids.includes(elementId)) {
    element.setAttribute(EVENT_LISTENED_ATTR, `${ids},${elementId}`.replace(/^,/, ''));
    addEventListenerToDom(element, {
      onClick,
    });
  }
}

// { type: Render } のMap的な定義
// 新しいRenderModuleを増やすとき
// 1. RenderModuleの実装
// 2. RENDER_MODULESに追加
// 3. RenderObjectの作成
const RENDER_MODULES: {
  [key in Exclude<Builder.VariationTypes, undefined | ''>]: RenderModule<key>;
} = {
  'after-html': afterHtmlModule(),
  'before-html': beforeHtmlModule(),
  'outer-html': outerHtmlModule(),
  'append-html': appendHtmlModule(),
  'blocks-element': outerHtmlModule(),
  'blocks-element-after': afterHtmlModule(),
  'blocks-element-before': beforeHtmlModule(),
  html: htmlModule(),
  text: textModule(),
  image: imageModule(),
  original: originalModule(),
  remove: removeModule(),
};

function render<T extends Builder.VariationTypes>(
  element: Element,
  renderObject: Builder.RenderObject<T>,
  option?: ShouldRenderFunctionOption,
): void {
  const { type } = renderObject;
  if (!type) return;
  if (Object.prototype.hasOwnProperty.call(RENDER_MODULES, type)) {
    const renderModule = RENDER_MODULES[type] as RenderModule<T>;
    if (!renderModule.shouldRender(element, renderObject, option)) {
      return;
    }
    renderModule.render(element, renderObject);
  }
}

/**
 * TODO: server side で render object を作りたい
 */
function createRenderObject(variation: Raw.Variation) {
  const { type } = variation;
  if (!type) {
    const object: Builder.RenderObject<typeof type> = {
      type: '',
      variationId: '',
      areaId: '',
      trackingId: '',
      blockAttributes: [],
    };
    return object;
  }

  const commonProps: Builder.RenderObjectCommonProps<typeof type> = {
    type,
    variationId: variation.variationId,
    areaId: variation.areaId,
    trackingId: variation.trackingId,
    blockAttributes: createBlockAttributes(variation),
  };
  if (
    type === 'blocks-element' ||
    type === 'blocks-element-after' ||
    type === 'blocks-element-before'
  ) {
    const { html, style, script } = variation;
    const object: Builder.RenderObject<typeof type> = {
      ...commonProps,
      type,
      html: decodeURIComponent(html || ''),
      style: decodeURIComponent(style || ''),
      script: decodeURIComponent(script || ''),
    };
    return object;
  } else if (type === 'outer-html' || type === 'after-html' || type === 'before-html') {
    // TODO: outer-html + styleのバグをbuilder.jsで吸収している
    // 解決したら、htmlはまとめる
    // https://github.com/plaidev/karte-io-systems/issues/27494
    const { html, style } = separateHtmlAndStyleForOuterHtml(variation.html || '');
    const script = decodeURIComponent(variation.script || '');
    const object: Builder.RenderObject<typeof type> = {
      ...commonProps,
      type,
      html,
      style,
      script,
    };
    return object as Builder.RenderObject<typeof type>;
  } else if (type === 'append-html' || type === 'html') {
    const html = decodeURIComponent(variation.html || '');
    const script = decodeURIComponent(variation.script || '');
    const object: Builder.RenderObject<typeof type> = {
      ...commonProps,
      type,
      html,
      style: '', // TODO: innerHTML系はhtmlにstyleが含まれているので空
      script,
    };
    return object;
  } else if (type === 'text') {
    const text = decodeURIComponent(variation.text || '');
    const object: Builder.RenderObject<typeof type> = {
      ...commonProps,
      type,
      text,
      rawElementAttributes: variation.elementAttributes,
    };
    return object;
  } else if (type === 'image') {
    const { imgUrl, aHref } = variation;
    const object: Builder.RenderObject<typeof type> = {
      ...commonProps,
      type,
      imgUrl,
      aHref,
      rawElementAttributes: variation.elementAttributes,
    };
    return object;
  } else {
    const object: Builder.RenderObject<typeof type> = {
      ...commonProps,
      type,
    };
    return object;
  }
}

export {
  render,
  rewrite,
  RENDER_MODULES,
  createRenderObject,
  addEventListenersToDoms,
  allocateIdToBlockArea,
  allocateSkippedIdToBlockArea,
};
