import { makeReduceKey } from '../window/reduceKey';
import { getStorage } from './Storage';
var DimensionStorage = /** @class */ (function () {
    function DimensionStorage(storageType) {
        this.storage = getStorage(storageType, 'krtdim');
    }
    DimensionStorage.prototype.getDimensionIdKey = function (dimensionId) {
        return "d-".concat(dimensionId);
    };
    DimensionStorage.prototype.getDimension = function (dimensionId) {
        var key = this.getDimensionIdKey(dimensionId);
        var val = this.storage.get(key);
        if (!val)
            val = { v: undefined, k: makeReduceKey() };
        return val;
    };
    DimensionStorage.prototype.getAllDimensions = function () {
        return this.storage.getAll();
    };
    DimensionStorage.prototype.getDimensionValue = function (dimensionId) {
        return this.getDimension(dimensionId).v;
    };
    DimensionStorage.prototype.setDimension = function (dimensionId, tw, dVal) {
        dVal.tw = tw;
        var key = this.getDimensionIdKey(dimensionId);
        this.storage.set(key, dVal);
    };
    DimensionStorage.prototype.setDimensionValue = function (dimensionId, val) {
        var dVal = this.getDimension(dimensionId);
        dVal.v = val;
        var key = this.getDimensionIdKey(dimensionId);
        this.storage.set(key, dVal);
    };
    DimensionStorage.prototype.clearDimensionByTw = function (tw) {
        var _this = this;
        var values = this.storage.getAll();
        Object.keys(values).forEach(function (key) {
            var dVal = values[key];
            if (dVal.tw == tw)
                _this.storage.del(key);
        });
    };
    return DimensionStorage;
}());
export { DimensionStorage };
export function getDimensionStorage(storageType) {
    return new DimensionStorage(storageType);
}
