import type { actionTableClient } from 'rewrite-common';

type ActionTableClient = actionTableClient.ActionTableClient;

type Callback<Result> = (err: any, result: Result) => void;

export interface CollectionAPI {
  get(key: string | string[], callback: Callback<any>): void;
  getByQuery(
    queryName: string,
    params: Record<string, any>,
    options: Record<string, any>,
    callback: Callback<any[]>,
  ): void;
}

export function createCollectionAPI(
  tableName: string,
  actionTableClient: ActionTableClient,
): CollectionAPI {
  return {
    get(key, callback) {
      if (Array.isArray(key)) {
        actionTableClient
          .getByKeys({
            tableName,
            keys: key,
          })
          .then(result => callback(null, result))
          .catch(err => callback(err, null));
      } else {
        actionTableClient
          .getByKey({
            tableName,
            key,
          })
          .then(result => callback(null, result))
          .catch(err => callback(err, []));
      }
    },
    getByQuery(queryName, params, options, callback) {
      actionTableClient
        .getByQuery({
          tableName,
          queryName,
          queryParams: params,
          queryOptions: options,
        })
        .then(result => {
          callback(null, result);
        })
        .catch(err => callback(err, []));
    },
  };
}
