/**
 * Array#findのponyfill
 * https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Array/find
 * @param items
 * @param predicate
 */
export const find = <T>(
  items: T[] | readonly T[],
  predicate: (item: T, index: number, items: T[] | readonly T[]) => unknown,
): T | undefined => {
  if (typeof predicate !== 'function') {
    throw new TypeError('callback must be a function');
  }
  const length = items.length >>> 0;
  for (let i = 0; i < length; i++) {
    const element = items[i];
    if (predicate(element, i, items)) {
      return element;
    }
  }
};
