import { Builder, Raw } from '../config_types';
import { ElementAttributeId, elementAttributeId } from '../elementAttributes/id';
import { ElementAttributesOptions } from '../elementAttributes/ElementAttributes';
import { ElementAttributeClass, elementAttributeClass } from '../elementAttributes/class';
import { ElementAttributeAlt, elementAttributeAlt } from '../elementAttributes/alt';
import { ElementAttributeStyle, elementAttributeStyle } from '../elementAttributes/style';
import { ShouldRenderFunction } from './types';
import { find } from '../polyfill/find';
import { objectAssign } from '../polyfill/object.assign';
import { simpleRandomHash } from '../hash';

const htmlCache: { [key: string]: { html: string; style: string } } = Object.create(null);

// HTMLとStyleを分解する
// template的にdivのinnerHTMLに入れると、imgのリクエストが毎回発生するため、分解をしつつキャッシュする関数
// https://github.com/plaidev/karte-io-systems/issues/35902
function htmlToElementsCached(htmlString: string): { html: string; style: string } {
  const cacheElement = htmlCache[htmlString];
  if (cacheElement) {
    return cacheElement;
  }
  const fragment = document.createElement('div');
  fragment.innerHTML = htmlString;
  // fistChildだとコメントが入る。firstChildElement or childrenの場合はコメントが対象外となる
  // https://github.com/plaidev/karte-io-systems/issues/33720
  const children = Array.prototype.slice.call(fragment.children) as Element[];
  const style = find(children, child => child.tagName === 'STYLE');
  // <style>のみの場合はHTMLはないものとして扱う
  const html = children[0] !== style ? children[0] : undefined;
  const elements = {
    html: html?.outerHTML ?? '',
    style: style?.innerHTML ?? '',
  };
  htmlCache[htmlString] = elements;
  return elements;
}

/**
 * @deprecated TODO: outer-html + styleのバグをbuilder.jsで吸収している
 */
export function separateHtmlAndStyleForOuterHtml(htmlAndStyle: string): {
  html: string;
  style: string;
} {
  const html = decodeURIComponent(htmlAndStyle);
  const elements = htmlToElementsCached(html);
  return {
    html: elements.html,
    style: elements.style,
  };
}

export function findParentATag(element: Element | null): Element | null {
  if (!element) return null;
  const parent = element.parentElement;
  if (parent && parent.tagName === 'A' && parent.getAttribute('href')) {
    return parent;
  } else {
    return findParentATag(parent);
  }
}

/**
 * Aタグはクリック計測対象
 */
export function findATagInChildren(element: Element | null): Element[] {
  if (!element) return [];

  if (element.tagName.toLowerCase() === 'a') return [element];

  const aTagCollection = element.getElementsByTagName('a');
  return [].slice.call(aTagCollection);
}

/**
 * buttonタグはクリック計測対象
 */
export function findButtonInChildren(element: Element | null): Element[] {
  if (!element) return [];

  if (element.tagName.toLowerCase() === 'button') return [element];

  const buttonCollection = element.getElementsByTagName('button');
  return [].slice.call(buttonCollection);
}

/**
 * 以下のinputタグはクリック計測対象
 * <input type="button">
 * <input type="submit">
 * <input type="image">
 * @param element
 * @returns
 */
export function findInputInChildren(element: Element | null): Element[] {
  if (!element) return [];

  if (element.tagName.toLowerCase() === 'input') {
    const type = element.getAttribute('type')?.toLowerCase();
    if (type === 'button' || type === 'submit' || type === 'image') {
      return [element];
    }
  }

  const inputList = element.querySelectorAll(
    'input[type=button], input[type=submit], input[type=image]',
  );
  return [].slice.call(inputList);
}

/**
 * クリック計測イベントをセットする
 * 計測対象のタグは以下の5つ
 * <a>
 * <button>
 * <input type="button">
 * <input type="submit">
 * <input type="image">
 *
 * element自身、もしくは対象となるタグが子要素にある場合、
 * どれをクリックしても1クリックとして計測する
 *
 * 計測対象タグが一つも含まれてない場合、
 * 先祖のAタグを探し、あればそのAタグを計測対象とする
 */
export function addEventListenerToDom(
  element: Element,
  { onClick }: { onClick: EventListenerOrEventListenerObject },
) {
  const aTagList = findATagInChildren(element);
  const buttonList = findButtonInChildren(element);
  const inputList = findInputInChildren(element);

  const isEmpty = aTagList.length === 0 && buttonList.length === 0 && inputList.length === 0;

  if (isEmpty) {
    // 子要素もしくはelement自身に計測対象のタグがないので、先祖のAタグを探す
    const aTag = findParentATag(element);
    if (!aTag) return;
    aTag.addEventListener('click', onClick);
  } else {
    aTagList.forEach(a => {
      a.addEventListener('click', onClick);
    });

    buttonList.forEach(a => {
      a.addEventListener('click', onClick);
    });

    inputList.forEach(a => {
      a.addEventListener('click', onClick);
    });
  }
}

export const BLOCK_AREA_ATTR = 'data-krt-blocks-area';
export const BLOCK_ID_ATTR = 'data-krt-blocks-id';
export const TRACKING_ID_ATTR = 'data-krt-blocks-tracking-id';
export const CSS_SELECTOR_ATTR = 'data-krt-blocks-css-selector';

export function createBlockAttributes(
  variation: Pick<Raw.Variation, 'trackingId' | 'variationId'> &
    Partial<Pick<Raw.Variation, 'cssSelector'>>,
): Builder.BlockAttributes {
  const attributes: Builder.BlockAttribute[] = [
    // rewriteの処理外からは、CSSセレクタ [data-krt-blocks-id=variationId] で書き換えた要素を選択できる
    { key: BLOCK_ID_ATTR, value: variation.variationId },
  ]
    // HTML実装のブロックはクラスが失われCSSセレクタが効かなくなことがあるので、data-krt-blocks-css-selector属性を付与する
    .concat(variation.cssSelector ? [{ key: CSS_SELECTOR_ATTR, value: variation.cssSelector }] : [])
    .concat(variation.trackingId ? [{ key: TRACKING_ID_ATTR, value: variation.trackingId }] : []);
  return attributes;
}

/**
 * rendering する対象につける attribute.
 * この attr がついてる element を書き換えることにする
 */
export function createBlockAreaAttributes(
  variation: Pick<Raw.Variation, 'areaId'>,
): Builder.BlockAttributes {
  const attributes: Builder.BlockAttribute[] = [
    // CSSセレクタ [data-krt-blocks-area=areaId] を先に付与する
    { key: BLOCK_AREA_ATTR, value: variation.areaId },
  ];
  return attributes;
}

export const CSS_BLOCK_ATTR = 'data-krt-blocks-css';

export function appendCSSToElement(element: Element, css: string): void {
  if (!css) return;
  if (element.querySelector(`[${CSS_BLOCK_ATTR}]`)) return;
  const cssTag = document.createElement('style');
  cssTag.innerHTML = css;
  cssTag.setAttribute(CSS_BLOCK_ATTR, '');
  element.appendChild(cssTag);
}

export const SCRIPT_BLOCK_ATTR = 'data-krt-blocks-script';
export const SCRIPT_VARIATION_ID_IDENTIFIER = '__karteVariationId';

export function executeScript({
  script,
  variationId,
}: {
  script: string;
  variationId: string;
}): void {
  if (!script) return;
  const scriptBlock = document.querySelector(`[${SCRIPT_BLOCK_ATTR}]`);
  if (scriptBlock) {
    scriptBlock.remove();
  }
  const scriptTag = document.createElement('script');
  scriptTag.text = `(function(${SCRIPT_VARIATION_ID_IDENTIFIER}) {\n${script}\n})('${variationId}')`;
  scriptTag.setAttribute(SCRIPT_BLOCK_ATTR, simpleRandomHash());
  document.head.appendChild(scriptTag);
}

/**
 * 書き換えた要素を選択するCSSセレクタを作成して返す
 * 書き換え済みの要素にはBLOCK_ID_ATTRが設定されている
 * 書き換えた結果、DOMの構造がずれた場合にvariation.cssSelectorではマッチしなくなるため、BLOCK_ID_ATTRをベースに書き換えた要素を選択する
 */
export function createBlockSelector(variationId: string) {
  return `[${BLOCK_ID_ATTR}="${variationId}"]`;
}

/**
 * 書き換え前に書き換え対象に付与されるCSSセレクタを返す。
 * 書き換え後にも付与されるので、書き換え対象に対して操作したいときに使える。
 * @param areaId
 */
export function createBlockAreaSelector(areaId: string) {
  return `[${BLOCK_AREA_ATTR}="${areaId}"]`;
}

/**
 * 既に書き換えられてたら render しない
 */
export const shouldRenderWhenNotRenderedYet: ShouldRenderFunction<any> = (
  element,
  { variationId },
): boolean => {
  return !document.querySelector(createBlockSelector(variationId));
};

/**
 * ElementAttributeで対応している属性の型一覧
 */
type ElementAttribute =
  | ElementAttributeId
  | ElementAttributeClass
  | ElementAttributeAlt
  | ElementAttributeStyle;
type ElementAttributes = ElementAttribute[];

export function createElementAttributes(
  element: Element,
  elementAttributes: Raw.Variation['elementAttributes'],
): ElementAttributes {
  if (!elementAttributes) {
    return [];
  }
  // それぞれの属性で使うオプションオブジェクトは共通なので最初に作成する
  const elementAttributeOptions: ElementAttributesOptions = {
    element: element,
    variation: {
      elementAttributes: elementAttributes,
    },
  };
  // それぞれの属性作成して合成する
  // 合成する際に空配列は展開されて取り除かれるので、空配列を返せば何もしない
  return [
    ...elementAttributeId(elementAttributeOptions),
    ...elementAttributeClass(elementAttributeOptions),
    ...elementAttributeAlt(elementAttributeOptions),
    ...elementAttributeStyle(elementAttributeOptions),
  ];
}

export function setAttributesToBlock({
  element,
  elementAttributes,
  blockAttributes,
}: {
  element: Element;
  elementAttributes?: ElementAttributes;
  blockAttributes?: Builder.BlockAttributes;
}): void {
  blockAttributes?.forEach(attribute => {
    element.setAttribute(attribute.key, attribute.value);
  });
  elementAttributes?.forEach(attribute => {
    if (typeof attribute.value === 'object') {
      // style属性はattributeの値としてオブジェクトを扱う
      // Object.assign(element.style, attribute.value); でまとめてstyleを設定する
      // https://stackoverflow.com/questions/3968593/how-can-i-set-multiple-css-styles-in-javascript
      // @ts-expect-error: style属性のためのマージなのでMapped Typeのエラーは無視
      objectAssign(element[attribute.key], attribute.value);
    } else {
      element.setAttribute(attribute.key, attribute.value);
    }
  });
}
