import { isNumberValue } from '../guard';
import { makeReduceKey } from '../window/reduceKey';
var CountStats = /** @class */ (function () {
    function CountStats() {
    }
    CountStats.prototype.dataType = function () {
        return 'any';
    };
    CountStats.prototype.initialValue = function () {
        return { v: 0, k: 0 };
    };
    CountStats.prototype.calculateFromValue = function (oldValue) {
        var newValue = this.calculate(isNumberValue(oldValue.v) ? oldValue.v : 0);
        return newValue;
    };
    CountStats.prototype.calculateFromStats = function (oldValue, value) {
        var newValue = this.calculate(isNumberValue(oldValue.v) ? oldValue.v : 0, isNumberValue(value.v) ? value.v : 0);
        return newValue;
    };
    CountStats.prototype.gc = function (values) {
        return values;
    };
    CountStats.prototype.calculate = function (oldCnt, cnt) {
        if (cnt === void 0) { cnt = 1; }
        var newValue = { v: 0, k: makeReduceKey() };
        newValue.v = oldCnt + cnt;
        return newValue;
    };
    return CountStats;
}());
export default new CountStats();
