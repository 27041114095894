import { edgeDimensionTypes as t } from 'rewrite-common';
import Calculator from './calculator';
import defaultEventGenerator from './default-event';
import Tracker from './tracker';
import { observeMutation } from './observer';
export default (function (config) {
    var calculator = new Calculator(config, t.StorageType.LOCAL_STORAGE);
    var tracker = new Tracker(calculator);
    defaultEventGenerator(tracker);
    observeMutation(document.documentElement || document.body, {
        onUrlChanged: function () {
            defaultEventGenerator(tracker);
        },
    });
});
