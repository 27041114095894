export var get = function (obj, path, defValue) {
    // If path is not defined or it has false value
    if (!path)
        return defValue;
    // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
    // Regex explained: https://regexr.com/58j0k
    var pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
    if (!pathArray || pathArray.length === 0)
        return defValue;
    // Find value if exist return otherwise return undefined value;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var value = pathArray.reduce(function (prevObj, key) { return prevObj && prevObj[key]; }, obj);
    return value !== undefined ? value : defValue;
};
