export function getTarget(url, target) {
    const obj = new URL(url);
    if (target === 'origin') {
        return obj.origin;
    }
    else if (target === 'url') {
        return obj.origin + obj.pathname + obj.search;
    }
    else if (target === 'path') {
        return obj.pathname;
    }
    else if (target === 'query') {
        return obj.search;
    }
    else if (target === 'old_url') {
        return obj.origin + obj.pathname;
    }
    else {
        return obj.href;
    }
}
export function isUrlMatch(url, matchNode) {
    const { match, value } = matchNode;
    const target = getTarget(url, matchNode.target);
    if (match === 'exact') {
        return target === value;
    }
    else if (match === 'forward') {
        return target.indexOf(value) === 0;
    }
    else if (match === 'backward') {
        const lengthDiff = target.length - value.length;
        return lengthDiff >= 0 && target.lastIndexOf(value) === lengthDiff;
    }
    else if (match === 'partial') {
        return target.indexOf(value) !== -1;
    }
    else if (match === 'regex') {
        return new RegExp(value).test(target);
    }
    else {
        return false;
    }
}
export function isMatch(url, node) {
    if (isMatchNode(node)) {
        return isUrlMatch(url, node);
    }
    else {
        if (node.operator === 'not') {
            return !isMatch(url, node.operand);
        }
        else if (node.operator === 'and') {
            return node.operands.every(node => isMatch(url, node));
        }
        else if (node.operator === 'or') {
            return node.operands.some(node => isMatch(url, node));
        }
        else {
            return false;
        }
    }
}
export function isMatchNode(node) {
    if (Object.prototype.hasOwnProperty.call(node, 'operator')) {
        return false;
    }
    else {
        return true;
    }
}
export function isUnaryLogicalNode(node) {
    if (isMatchNode(node))
        return false;
    return node.operator === 'not';
}
export function optimizeLogicalNode(node) {
    if (isMatchNode(node) || isUnaryLogicalNode(node)) {
        return node;
    }
    else if (node.operands.length === 0) {
        throw new Error('node.operands.length === 0');
    }
    else if (node.operands.length === 1) {
        return optimizeLogicalNode(node.operands[0]);
    }
    else {
        return {
            operator: node.operator,
            operands: node.operands.map(optimizeLogicalNode),
        };
    }
}
