import { find } from './polyfill/find';

interface SupportedObject {
  type: string;
  typeRegex: RegExp;
  version: string;
  versionRegex: RegExp;
  isMobile?: boolean;
  supportedBrowser?: string[];
}

const supportedOs: SupportedObject[] = [
  {
    type: 'windows',
    isMobile: false,
    version: '6.1',
    typeRegex: /windows nt/,
    versionRegex: /windows nt ([\d]+).([\d]+)/,
  }, // versionの6.1がwindows7にあたる
  {
    type: 'mac',
    isMobile: false,
    version: '10.9',
    typeRegex: /macintosh/,
    versionRegex: /mac os x ([\d]+)[_.]([\d]+)/,
  },
  {
    type: 'iphone',
    isMobile: true,
    version: '8.0',
    typeRegex: /iphone/,
    versionRegex: /iphone os ([\d]+)_([\d]+)/,
  },
  {
    type: 'ipad',
    isMobile: true,
    version: '8.0',
    typeRegex: /ipad/,
    versionRegex: /cpu os ([\d]+)_([\d]+)/,
  },
  {
    type: 'android',
    isMobile: true,
    version: '4.4',
    typeRegex: /android/,
    versionRegex: /android ([\d]+).([\d]+).([\d]+)/,
    supportedBrowser: [],
  },
];

const supportedPcBrowser: SupportedObject[] = [
  {
    type: 'firefox',
    typeRegex: /firefox/,
    version: '73.0',
    versionRegex: /firefox\/([\d]+).([\d]+)/,
  },
  { type: 'edge', typeRegex: /edge/, version: '80.0', versionRegex: /edge\/([\d]+).([\d]+)/ },
  { type: 'ie', typeRegex: /trident/, version: '7.0', versionRegex: /trident\/([\d]+).([\d]+)/ }, //miseは10以下なので判定しない
  { type: 'chrome', typeRegex: /chrome/, version: '80.0', versionRegex: /chrome\/([\d]+).([\d]+)/ }, //'Chromeの文字列は他にも使われているので、最後に残ったやつでchromeが入っていたらchrome
];

const supportedMobileBrowser: SupportedObject[] = [
  { type: 'chrome', typeRegex: /chrome/, version: '80.0', versionRegex: /chrome\/([\d]+).([\d]+)/ }, //'Chromeの文字列は他にも使われているので、最後に残ったやつでchromeが入っていたらchrome
  { type: 'chrome', typeRegex: /crios/, version: '80.0', versionRegex: /crios\/([\d]+).([\d]+)/ },
  {
    type: 'safari',
    typeRegex: /safari/,
    version: '601.0',
    versionRegex: /safari\/([\d]+).([\d]+)/,
  },
];

function checkSupportedType(
  lowerUa: string,
  supportedObjects: SupportedObject[],
): SupportedObject | undefined {
  const target = find(supportedObjects, i => lowerUa.match(i.typeRegex));
  return target;
}

function isSupportedVersion(lowerUa: string, supportedConfig: SupportedObject): boolean {
  const version = lowerUa.match(supportedConfig.versionRegex);
  if (!version) return false;
  const configVersion = supportedConfig.version.split('.');
  if (parseInt(version[1]) > parseInt(configVersion[0])) {
    return true;
  } else if (parseInt(version[1]) < parseInt(configVersion[0])) {
    return false;
  } else if (parseInt(version[2]) >= parseInt(configVersion[1])) {
    return true;
  }
  return false;
}

function isSupportedUserAgent(userAgent: string): boolean {
  const ua = userAgent.toLowerCase();
  const osConf = checkSupportedType(ua, supportedOs);

  if (!osConf || !isSupportedVersion(ua, osConf)) {
    return false;
  }

  const pcBrowserConf = checkSupportedType(ua, supportedPcBrowser);
  if (!osConf.isMobile && pcBrowserConf && !isSupportedVersion(ua, pcBrowserConf)) {
    return false;
  }

  const mobileBrowserConf = checkSupportedType(ua, supportedMobileBrowser);
  if (osConf.isMobile && mobileBrowserConf && !isSupportedVersion(ua, mobileBrowserConf)) {
    return false;
  }

  return true;
}

export { isSupportedUserAgent };
