import { getStorage } from './Storage';
var TimeWindowKeyStorage = /** @class */ (function () {
    function TimeWindowKeyStorage(storageType) {
        this.storage = getStorage(storageType, 'krttw');
    }
    TimeWindowKeyStorage.prototype.getStorageKey = function (tw) {
        return "t-".concat(tw);
    };
    TimeWindowKeyStorage.prototype.getTimeWindowKey = function (tw) {
        return this.storage.get(this.getStorageKey(tw));
    };
    TimeWindowKeyStorage.prototype.setTimeWindowKey = function (tw, key) {
        this.storage.set(this.getStorageKey(tw), key);
    };
    return TimeWindowKeyStorage;
}());
export { TimeWindowKeyStorage };
export function getTimeWindowKeyStorage(storageType) {
    return new TimeWindowKeyStorage(storageType);
}
