/**
 * Object.assignのpolyfill
 * https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
 * @param target
 * @param source
 */
export function objectAssign<T, U>(target: T, source: U): T & U;
export function objectAssign<T, U, V>(target: T, source1: U, source2: V): T & U & V;
export function objectAssign<T, U, V, W>(
  target: T,
  source1: U,
  source2: V,
  source3: W,
): T & U & V & W;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function objectAssign(target: any, ...sources: any[]): any {
  if (target === null || target === undefined) {
    throw new TypeError('Cannot convert undefined or null to object');
  }
  const to = Object(target);
  for (let index = 0; index < sources.length; index++) {
    const nextSource = sources[index];
    if (nextSource !== null && nextSource !== undefined) {
      for (const nextKey in nextSource) {
        // Avoid bugs when hasOwnProperty is shadowed
        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
          to[nextKey] = nextSource[nextKey];
        }
      }
    }
  }
  return to;
}
