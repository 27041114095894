import { get, set } from './storage-util';
import { uuid } from './uuid';
import { PartsEvent } from './sender';
import { getDomain } from './domain';
import * as cookie from './cookie';
import envData from '~data/data';
import { DimensionValue } from 'rewrite-dimension-lib';

const LOCAL_KEY = {
  KRT_SEGMENTS_KEY_FOR_TRACK: 'krt___segments',
  // preBuilder と builder で同じconfig を使っていて、preBuilder の時に krt_rewrite_config が 取れない
  KRT_SEGMENTS_KEY_FOR_EDGE: `krt.${envData.krt_rewrite_config?.apiKey}.segments`,
  KRT_VID_KEY_FOR_MULTI_DOMAIN: 'krt.__ktid',
  KRT_VID_KEY: 'krt___krt.vis',
  KRT_STORAGE_KEY_FOR_EDGE: `krt@${envData.krt_rewrite_config?.apiKey.slice(0, 5)}.detect$`,
  KRT_STORAGE_SHARED_KEY_FOR_EDGE: 'd',
  KRT_VID_KEY_IN_COOKIE: 'krt.vis',
  KRT_REWRITE_UID: 'krt_rewrite_uid',
  KRT_REWRITE_CONDITION_VAL: 'krt_rewrite_condition_val',
  KRT_REWRITE_CONTROL_GROUP_DISABILITY: 'krt_rewrite_control_group_disability',
  KRT_REWRITE_LOGGER_ENABLE: 'krt_rewrite_logger_enable',
  KRT_REWRITE_IS_CONTROL_FOR_ALL: 'krt_rewrite_is_control_for_all',
  KRT_REWRITE_IS_CONTROL_FOR_CONDITION: 'krt_rewrite_is_control_for_condition',
  KRT_BLOCKS_DIMENSION: 'krtdim',
  KRT_BLOCKS_SEGMENTS: 'krt_rewrite_segments',

  // 施策の優先度を使って実装するかどうかのフラグ
  KRT_REWRITE_PRIORITY: 'krt_rewrite_campaign_priority',
};

const SESSION_KEY = {
  KRT_REWRITE_SKIP_BUILDERJS: 'krt_rewrite_skip_builderjs',
  KRT_REWRITE_IS_PREVIEW: 'krt_rewrite_preview',
  KRT_REWRITE_IS_BLOCK_PERFORMANCE_MODE: 'krt_rewrite_preview_block_performance_mode',
  KRT_REWRITE_PREVIEW_PAGE_GROUP: 'krt_rewrite_preview_page_group',
  KRT_REWRITE_PREVIEW_CONDITION: 'krt_rewrite_preview_condition',
  KRT_REWRITE_PREVIEW_PATTERN: 'krt_rewrite_preview_pattern',
};

type BlocksDimensionInLocalStorage = Record<string, DimensionValue>;

type BlocksDimension = Record<string, string | number | null | undefined> | null;

type State = Readonly<{
  rewriteUid: string;
  conditionVal: number;
  karteVisitorId: string;
  segments: string[] | [] | null;
  blocksDimensions: BlocksDimension;
  blocksSegments: string | undefined;

  // for rewrite extension
  // trueならbuilder.jsの処理は実行しない
  shouldSkipBuilderJs: boolean;
  isPreview: boolean;
  isBlockPerformanceMode: boolean;
  previewPageGroup: string;
  previewCondition: string;
  previewPattern: string;
  partsEventList: {
    sent: boolean;
    partsEvent: PartsEvent;
  }[];
}>;

const sanitizer = (str: string): string => {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;');
};

function getDimensions(): BlocksDimension {
  const dimensionsStr = window.localStorage.getItem(LOCAL_KEY.KRT_BLOCKS_DIMENSION);
  if (!dimensionsStr) {
    return null;
  }
  try {
    const dimensions: BlocksDimensionInLocalStorage = JSON.parse(dimensionsStr);
    const convertedDimensions: BlocksDimension = {};

    Object.keys(dimensions).forEach(k => {
      const dimension: DimensionValue = dimensions[k as keyof typeof dimensions];
      const shortenId = dimension.shortenId;
      if (shortenId) {
        const safeShortenId = sanitizer(shortenId);
        // BQでデータを扱う時のために undefined は null で送る
        if (typeof dimension.v === 'undefined') {
          convertedDimensions[safeShortenId] = null;
        } else {
          convertedDimensions[safeShortenId] = dimension.v;
        }
      }
    });
    return convertedDimensions;
  } catch (err) {
    console.error(err);

    return null;
  }
}

function getDimensionsForTest(): BlocksDimension {
  return getDimensions();
}

function read_with_parse(storageKey: string) {
  const ret = window.localStorage.getItem(storageKey);
  if (ret == null) {
    return null;
  }
  try {
    return JSON.parse(ret);
  } catch (e) {
    // JSON.stringify 可能な形式以外で保存されていた場合、その文字列をそのまま返す
    return typeof ret === 'string' ? ret : null;
  }
}

// see also. edge.jsのロジック
// https://github.com/plaidev/edgejs-plugins/blob/main/core/features/storage.ts
function getFromEdgeLocalStorage(storageKey: string, valueKey: string) {
  const shared = read_with_parse(storageKey + LOCAL_KEY.KRT_STORAGE_SHARED_KEY_FOR_EDGE);
  if (shared != null) {
    if (shared[valueKey] != null) {
      return shared[valueKey];
    }
  }
  const local = read_with_parse(storageKey + valueKey);
  if (local != null) {
    return local;
  }
  const global = read_with_parse(valueKey);
  if (global != null) {
    return global;
  }
  return null;
}

// 責務を分割するため、state のロジックの中では config を直接触らないようにする
function createState(): State {
  const getFromLocal = get(window.localStorage);
  const getFromSession = get(window.sessionStorage);
  const setToLocal = set(window.localStorage);
  const uidInCookie = cookie.get(LOCAL_KEY.KRT_REWRITE_UID);
  const vidInCookie = cookie.getEdge(LOCAL_KEY.KRT_VID_KEY_IN_COOKIE);
  const uidInLocalStorage: string = getFromLocal(LOCAL_KEY.KRT_REWRITE_UID) || '';

  // get or initialize uid
  // ゴール画面でサブドメインでも計測させるためにcookieにもrewriteUidを保存しておく
  // cookieの上限値を越えるなどしてcookieに該当のkeyがない場合はlocalStorageも見る
  const rewriteUid = uidInCookie || uidInLocalStorage || uuid();
  if (!uidInCookie) {
    const domain = getDomain(window.location.href);
    if (domain) {
      const ops: cookie.CookieOptions = { path: '/', secure: true };

      ops.domain = domain;
      ops.maxAge = 1000 * 60 * 60 * 24 * 30; // 30日
      cookie.set(LOCAL_KEY.KRT_REWRITE_UID, rewriteUid, ops);
    }
  }

  setToLocal(LOCAL_KEY.KRT_REWRITE_UID, rewriteUid);

  // get or initialize condition value
  const conditionVal: number =
    getFromLocal(LOCAL_KEY.KRT_REWRITE_CONDITION_VAL) || Math.floor(Math.random() * 10000);
  setToLocal(LOCAL_KEY.KRT_REWRITE_CONDITION_VAL, conditionVal);

  return {
    rewriteUid: rewriteUid,
    conditionVal: conditionVal,
    // vidの判定優先順位は cookie(edge/tracker共通) > localStorage(edge) > localStorage(tracker)
    karteVisitorId:
      vidInCookie ||
      getFromEdgeLocalStorage(
        LOCAL_KEY.KRT_STORAGE_KEY_FOR_EDGE,
        LOCAL_KEY.KRT_VID_KEY_IN_COOKIE,
      ) ||
      getFromLocal(LOCAL_KEY.KRT_VID_KEY) ||
      '',
    // edge と tracker で localStorage の segment の key が違う
    segments:
      getFromLocal(LOCAL_KEY.KRT_SEGMENTS_KEY_FOR_EDGE) ||
      getFromLocal(LOCAL_KEY.KRT_SEGMENTS_KEY_FOR_TRACK) ||
      null,
    blocksDimensions: getDimensions(),
    blocksSegments: window.localStorage.getItem(LOCAL_KEY.KRT_BLOCKS_SEGMENTS) || undefined,

    // for rewrite extension
    shouldSkipBuilderJs: getFromSession(SESSION_KEY.KRT_REWRITE_SKIP_BUILDERJS) || false,
    isPreview: getFromSession(SESSION_KEY.KRT_REWRITE_IS_PREVIEW) || false,
    isBlockPerformanceMode:
      getFromSession(SESSION_KEY.KRT_REWRITE_IS_BLOCK_PERFORMANCE_MODE) || false,
    previewPageGroup: getFromSession(SESSION_KEY.KRT_REWRITE_PREVIEW_PAGE_GROUP) || '',
    previewCondition: getFromSession(SESSION_KEY.KRT_REWRITE_PREVIEW_CONDITION) || '',
    previewPattern: getFromSession(SESSION_KEY.KRT_REWRITE_PREVIEW_PATTERN) || '',
    partsEventList: [],
  };
}

/**
 * 施策の優先度を使って実装するかどうかのフラグを返す
 * @memo localStorage ではなく config の方がいいかも？
 */
function checkCampaignPriority(): boolean {
  return !!window.localStorage.getItem(LOCAL_KEY.KRT_REWRITE_PRIORITY);
}

export {
  LOCAL_KEY,
  SESSION_KEY,
  State,
  createState,
  BlocksDimension,
  getDimensionsForTest,
  BlocksDimensionInLocalStorage,
  checkCampaignPriority,
};
