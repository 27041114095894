export function parse(userAgent) {
    var brand = getBrand(userAgent);
    var version = getVersion(userAgent, brand);
    var platform = getPlatform(userAgent);
    var data = {
        full: userAgent,
        brand: brand,
        version: version,
        platform: platform,
    };
    return data;
}
var UNKNOWN = '?';
var brands = {
    Edge: [/edg(?:[ea]?|ios)\/(\d+?)\./i],
    Opera: [/op(?:r|t)\/(\d+?)\./i],
    Firefox: [/f(?:irefox|xios)\/(\d+?)\./i],
    Chrome: [/c(?:hrome|rios|rmo)\/(\d+?)\./i],
    Safari: [/safari/i, /version\/(\d+?)\./i],
    IE: [/(?:msie\s|trident.+[:\s])(\d+?)\./i],
};
var brandNames = Object.keys(brands);
function getBrand(str) {
    for (var i = 0, len = brandNames.length; i < len; ++i) {
        var re = brands[brandNames[i]];
        if (re[0].test(str)) {
            return brandNames[i];
        }
    }
    return UNKNOWN;
}
function getVersion(str, name) {
    if (name && brands[name]) {
        var re = brands[name];
        return (re[re.length - 1].exec(str) && RegExp.$1) || UNKNOWN;
    }
    else {
        var m = str.match(/version[\\/ ](\d+?)\./i);
        return m && m.length > 1 ? m[1] : UNKNOWN;
    }
}
var platforms = {
    iPad: /ipad/i,
    iPhone: /iphone/i,
    Android: /android/i,
    Windows: /windows nt/i,
    'OS X': /os x \d+[._]\d+/i,
    Linux: /linux/i,
};
var platformNames = Object.keys(platforms);
function getPlatform(str) {
    for (var i = 0, len = platformNames.length; i < len; ++i) {
        if (platforms[platformNames[i]].test(str)) {
            return platformNames[i];
        }
    }
    return UNKNOWN;
}
