import { RenderFunction, RenderModule } from './types';
import { setAttributesToBlock, shouldRenderWhenNotRenderedYet } from './utils';

export const render: RenderFunction<'remove'> = (element, { blockAttributes }) => {
  (element as HTMLElement).style.display = 'none';
  setAttributesToBlock({ element, blockAttributes });
};

export const create = (): RenderModule<'remove'> => {
  return {
    render,
    shouldRender: shouldRenderWhenNotRenderedYet,
  };
};
