import { edgeDimensionTypes as t } from 'rewrite-common';
import { AllTimeWindow } from './all';
import { DayTimeWindow } from './day';
import { MonthTimeWindow } from './month';
import { PreviousSessionTimeWindow } from './previousSession';
import { SessionTimeWindow } from './session';
import { WeekTimeWindow } from './week';
import { TestTimeWindow } from './_test';
var modules = {};
// 重要: SESSION -> PREVIOUS_SESSION の並び順は変えちゃダメ！
export function initializeTimeWindow(storageSet) {
    modules[t.TimeWindowType.ALL] = new AllTimeWindow(storageSet);
    modules[t.TimeWindowType.DAY] = new DayTimeWindow(storageSet);
    modules[t.TimeWindowType.WEEK] = new WeekTimeWindow(storageSet);
    modules[t.TimeWindowType.MONTH] = new MonthTimeWindow(storageSet);
    modules[t.TimeWindowType.SESSION] = new SessionTimeWindow(storageSet);
    modules[t.TimeWindowType.PREV_SESSION] = new PreviousSessionTimeWindow(storageSet);
    modules[t.TimeWindowType._TEST] = new TestTimeWindow(storageSet);
}
export function getTimeWindow(twType) {
    var module = modules[twType];
    return module;
}
export function changeTimeWindow() {
    return Object.keys(modules).filter(function (twType) {
        var module = modules[twType];
        return module.getCalculator().change(twType, module.getKey());
    });
}
