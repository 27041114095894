import { Directive } from '../dynamic-renderer/types';

export interface ImpressionObserver {
  observe(el: Element, onImpression: () => void): () => void;
  disconnect(): void;
}

function createImpressionObserver(): ImpressionObserver {
  const callbacks = new Map<Element, () => void>();

  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const targetEl = entry.target;
          const callback = callbacks.get(targetEl);
          if (callback) {
            callback();
            observer.unobserve(targetEl);
          }
        }
      });
    },
    {
      threshold: 0.5,
    },
  );

  function observe(el: Element, onImpression: () => void) {
    callbacks.set(el, onImpression);
    observer.observe(el);
    return () => {
      observer.unobserve(el);
    };
  }

  function disconnect() {
    observer.disconnect();
  }

  return {
    observe,
    disconnect,
  };
}

type TrackingEvent = {
  eventName: string;
  values: Record<string, any>;
};

function toEvent(value: any): TrackingEvent | undefined {
  const { eventName, values } = value ?? {};
  if (typeof eventName === 'string') {
    return {
      eventName,
      values: values ?? {},
    };
  }
}

interface Tracker {
  track(eventName: string, values?: any): void;
}

type Disposer = () => void;

function trackClickEvent(
  { el, event }: { el: HTMLElement; event: TrackingEvent },
  { tracker }: { tracker: Tracker },
): Disposer {
  function handler() {
    tracker.track(event.eventName, event.values);
  }
  el.addEventListener('click', handler);
  return () => {
    el.removeEventListener('click', handler);
  };
}

function trackImpressionEvent(
  { el, event }: { el: HTMLElement; event: TrackingEvent },
  { tracker, impressionObserver }: { tracker: Tracker; impressionObserver: ImpressionObserver },
): Disposer {
  const disposer = impressionObserver.observe(el, () => {
    tracker.track(event.eventName, event.values);
  });
  return () => {
    disposer();
  };
}

export const createTrackingDirective: ({ tracker }: { tracker: Tracker }) => {
  directive: Directive;
  dispose: () => void;
} = ({ tracker }) => {
  const impressionObserver = createImpressionObserver();

  const directive: Directive = ({ el, arg, effect, expr, evaluate }) => {
    let value = evaluate(expr);
    let disposer: (() => void) | undefined;

    effect(() => {
      disposer?.();
      value = evaluate(expr);
      const event = toEvent(value);
      if (event && el instanceof HTMLElement) {
        switch (arg) {
          case 'click':
            disposer = trackClickEvent({ el, event }, { tracker });
            break;
          case 'impression':
            disposer = trackImpressionEvent({ el, event }, { tracker, impressionObserver });
            break;
        }
      }
    });

    return () => {
      disposer?.();
    };
  };

  const dispose = () => {
    impressionObserver.disconnect();
  };

  return {
    directive,
    dispose,
  };
};
