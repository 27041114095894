import page_view from './page_view';
import timewindow from './timewindow';
import visibilitychange from './visibilitychange';
var timeWindowChangeListener;
function createTimeWindowChangeListener(tracker) {
    if (!timeWindowChangeListener) {
        timeWindowChangeListener = function (tw) {
            timewindow(tracker, tw);
        };
    }
    return timeWindowChangeListener;
}
export default (function (tracker) {
    tracker.calculator.addTimeWindowChangeListener(createTimeWindowChangeListener(tracker));
    page_view(tracker);
    visibilitychange(tracker);
});
