var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var ValueTypeError = /** @class */ (function (_super) {
    __extends(ValueTypeError, _super);
    function ValueTypeError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ValueTypeError;
}(Error));
export default function (dataType, value, path) {
    if (dataType === 'any')
        return;
    if (typeof value !== dataType)
        throw new ValueTypeError("invalid dataType: path: ".concat(path, " dataType: ").concat(dataType, " value: ").concat(value));
}
