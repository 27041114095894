import { ElementAttributesOptions } from './ElementAttributes';
/**
 * id属性
 */
export type ElementAttributeId = {
  key: 'id';
  value: string;
};
export const elementAttributeId = ({
  variation,
}: ElementAttributesOptions): ElementAttributeId[] => {
  const elementAttributes = variation.elementAttributes;
  // # id属性
  // "" 空文字列の場合は何も設定しない
  return elementAttributes.id ? [{ key: 'id', value: elementAttributes.id }] : [];
};
