const PERIOD = 30 * 24 * 60 * 60 * 1000;

interface StorageObject<T> {
  val: T;
  expire: boolean;
  last: number;
}

const get =
  (storage: Storage) =>
  <T>(key: string): T | undefined => {
    try {
      const obj = storage.getItem(key);
      if (!obj) {
        return undefined;
      }

      const json: StorageObject<T> = JSON.parse(obj);

      if (json.expire) {
        if (json.last + PERIOD <= new Date().getTime()) {
          storage.removeItem(key);
          return undefined;
        }
      }

      json.last = new Date().getTime();
      storage.setItem(key, JSON.stringify(json));

      return json.val;
    } catch (ex) {
      return undefined;
    }
  };

const set =
  (storage: Storage) =>
  <T>(key: string, value: T, expire = false): T | undefined => {
    const obj: StorageObject<T> = {
      expire: expire,
      last: new Date().getTime(),
      val: value,
    };

    try {
      storage.setItem(key, JSON.stringify(obj));
      return obj.val;
    } catch (ex) {
      return undefined;
    }
  };

export { get, set, StorageObject };
