const KRT_REWRITE_CSS_NAME = 'krt_rewrite_css_name';

export interface CSSOpacityTargets {
  originImgSrcArray: string[];
  cssSelectorArray: string[];
}

function createSelector(css: CSSOpacityTargets): string {
  return [
    ...css.originImgSrcArray.map(s => `img[src="${s}"]`),
    ...css.cssSelectorArray,
    KRT_REWRITE_CSS_NAME,
  ].join(',');
}

function insertVisibilityHiddenStyle(styleSheet: CSSStyleSheet, css: CSSOpacityTargets): void {
  const keyFrameCSS = ` 
    @keyframes ${KRT_REWRITE_CSS_NAME} {
      from {
        visibility: hidden;
      }
      to {
        visibility: visible;
      }
    }`;

  const selector = createSelector(css);
  const animationCSS = `
    ${selector} {
      animation: ${KRT_REWRITE_CSS_NAME} 2s step-end;
    }`;

  styleSheet.insertRule(keyFrameCSS, 0);
  styleSheet.insertRule(animationCSS, 0);
}

// 書き換え後にCSSルールの削除を行う
function removeStyle(styleSheet: CSSStyleSheet): void {
  for (let i = styleSheet.cssRules.length - 1; i >= 0; i--) {
    styleSheet.deleteRule(i);
  }
}

function createStyleSheet(): CSSStyleSheet {
  const style = document.createElement('style');
  document.head.appendChild(style);
  return style.sheet as CSSStyleSheet;
}

export { KRT_REWRITE_CSS_NAME, insertVisibilityHiddenStyle, removeStyle, createStyleSheet };
