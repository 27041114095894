import { FixedWindowCalculator } from '../window';
var DayTimeWindow = /** @class */ (function () {
    function DayTimeWindow(storageSet) {
        this.storageSet = storageSet;
    }
    DayTimeWindow.prototype.getCalculator = function () {
        return new FixedWindowCalculator(this.storageSet);
    };
    DayTimeWindow.prototype.getKey = function () {
        var date = new Date();
        return "".concat(date.getFullYear(), "-").concat(date.getMonth() + 1, "-").concat(date.getDate());
    };
    return DayTimeWindow;
}());
export { DayTimeWindow };
