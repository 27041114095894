import { isString, isDate, isPlainObject } from '../utils/typecheck';
import { schema } from './_schema';
// objectをdot notationに
export var to_dot = function (obj, initial_key) {
    if (initial_key == null) {
        initial_key = '';
    }
    var ret = {};
    for (var _i = 0, _a = Object.keys(obj || {}); _i < _a.length; _i++) {
        var k = _a[_i];
        var key = initial_key;
        if (key.length > 0) {
            key += '.' + k;
        }
        else {
            key += k;
        }
        var val = obj[k];
        if (Array.isArray(val)) {
            ret[key] = val;
        }
        else if (val !== null && typeof val === 'object') {
            if (val._bsontype === 'ObjectID') {
                ret[key] = val;
            }
            else if (Object.keys(val).length === 0) {
                // 空オブジェクトのときにイテレータが回らず消えるのを防ぐ
                ret[key] = {};
            }
            else {
                var temp = to_dot(val, key);
                // merge
                for (var _b = 0, _c = Object.keys(temp || {}); _b < _c.length; _b++) {
                    var tk = _c[_b];
                    ret[tk] = temp[tk];
                }
            }
        }
        else {
            ret[key] = val;
        }
    }
    return ret;
};
// keyを反転
export var convert_keys = function (dots, sch) {
    if (sch == null) {
        sch = schema;
    }
    var ret = {};
    for (var _i = 0, _a = Object.keys(dots || {}); _i < _a.length; _i++) {
        var key = _a[_i];
        if (key in sch) {
            ret[sch[key]] = dots[key];
        }
        else {
            ret[key] = dots[key];
        }
    }
    return ret;
};
// pack
export var pack = function (json, sch) {
    if (sch === void 0) { sch = schema; }
    var dots = to_dot(json, null);
    return convert_keys(dots, sch);
};
export var converter = function (obj_or_arr, type) {
    // 全て小文字に
    if (type === 'string') {
        if (isString(obj_or_arr)) {
            obj_or_arr = obj_or_arr.toString().toLowerCase();
        }
    }
    // 現状new Date() 型のみを受け付けているためコメントアウト
    // if type == 'date'
    //   if _.isString(obj_or_arr)
    //     s = obj_or_arr.match(/date\((.*)\)/)
    //     if s and (s.length > 1)
    //       m = moment(s[1])
    //       if m.isValid()
    //         return m.unix()
    //     else
    //       if obj_or_arr.search(/\d{4}(-|\/)\d{2}(-|\/)\d{2}/) == 0
    //         m = moment(obj_or_arr)
    //         if m.isValid()
    //           return m.unix()
    if (isPlainObject(obj_or_arr)) {
        // dateの正規化
        if (type === 'date') {
            // if obj_or_arr.name == "trackerDate"
            //   d = moment(obj_or_arr.source)
            //   if d
            //     obj_or_arr = d.toDate()
            //   else
            //     obj_or_arr = new Date()
            if (isDate(obj_or_arr)) {
                return Math.round(obj_or_arr.getTime() / 1000);
            }
        }
        for (var _i = 0, _a = Object.keys(obj_or_arr || {}); _i < _a.length; _i++) {
            var key = _a[_i];
            var nobj = obj_or_arr[key];
            if (key.indexOf('.') !== -1) {
                var oldkey = key;
                key = key.replace(/\./g, '_');
                delete obj_or_arr[oldkey];
            }
            obj_or_arr[key] = converter(nobj, type);
        }
    }
    return obj_or_arr;
};
