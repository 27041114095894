import { Builder, Raw } from './config_types';
import { logger } from './logger';
import { exponentialBackoff } from './exponentialBackoff';

type Event = {
  command: keyof Gtag.GtagCommands;
  args: Gtag.GtagCommands[keyof Gtag.GtagCommands];
};

const safeGtag = (
  command: keyof Gtag.GtagCommands,
  ...args: Gtag.GtagCommands[keyof Gtag.GtagCommands]
) => {
  try {
    gtag(command, ...args);
  } catch (e) {
    logger.info('failed to call gtag.');
    if (e instanceof Error) {
      logger.info({ message: e.message, stack: e.stack });
    }
  }
};

/**
 * GA4のイベント送信を行うクライアント
 * 初期化したら必ずprepareを呼び出すこと
 */
class Ga4IntegrationClient {
  readonly propertyId: string;
  readonly sendingDistributedPatternDataEnabled: boolean;
  private prepared = false;
  private gtagInstalled = false;
  private gtagInstallationChecked = false;
  private eventBuffer: Event[] = [];

  constructor(config: Raw.Config | Raw.PartialConfig) {
    this.propertyId = config.ga4Integration?.propertyId || '';
    this.sendingDistributedPatternDataEnabled =
      config.ga4Integration?.sendingDistributedPatternDataEnabled || false;
  }

  /**
   * gtagが設置されているかリトライ付きで確認する
   * 最終的に設置されていた確認が取れていた場合は、isGtagInstalledをtrueにして、
   * eventBufferにたまっていたイベントを送信する
   */
  async prepare() {
    if (this.prepared) {
      return;
    } else {
      this.prepared = true;
    }
    const checkGtagFn = () => {
      return typeof gtag === 'function';
    };
    const result = await exponentialBackoff(checkGtagFn, (result: boolean) => result);
    this.gtagInstallationChecked = true;
    this.gtagInstalled = !!result;
    if (!this.gtagInstalled) {
      logger.info('gtag is not installed.');
    } else {
      for (const event of this.eventBuffer) {
        safeGtag(event.command, ...event.args);
      }
    }
  }

  private async send(event: Event) {
    if (!this.gtagInstallationChecked) {
      this.eventBuffer.push(event);
      return;
    }
    if (!this.gtagInstalled) {
      return;
    } else {
      safeGtag(event.command, ...event.args);
    }
  }

  async sendDistributedPatternData(matchedPattern: Builder.Pattern) {
    if (!this.sendingDistributedPatternDataEnabled) {
      return;
    }

    // cf. https://developers.google.com/analytics/devguides/collection/ga4/integration?hl=ja#events
    await this.send({
      command: 'event',
      args: [
        'experience_impression',
        {
          exp_variant_string: `blocks_${matchedPattern.pageGroupId}_${matchedPattern.conditionId}_${matchedPattern.patternId}`,
          send_to: this.propertyId,
        },
      ],
    });
  }
}

export const newGa4IntegrationClient = (config: Raw.Config | Raw.PartialConfig) => {
  const client = new Ga4IntegrationClient(config);
  client.prepare();
  return client;
};

// for テスト
export const newGa4IntegrationClientAsync = async (config: Raw.Config | Raw.PartialConfig) => {
  const client = new Ga4IntegrationClient(config);
  await client.prepare();
  return client;
};
