import { RenderFunction, RenderModule } from './types';
import {
  shouldRenderWhenNotRenderedYet,
  setAttributesToBlock,
  createElementAttributes,
} from './utils';

/**
 * \nを<br>として追加する
 */
export function rewriteTextWithLinebreak(element: Element, text: string) {
  // reset content
  element.textContent = '';
  // text and line break
  const lineTexts = text.split(/\r?\n|\r/g); // note: firefox needs "\r" passed in "\r\n".
  const lineTextsCount = lineTexts.length - 1;
  lineTexts.forEach((text, index) => {
    element.appendChild(document.createTextNode(text));
    if (index < lineTextsCount) {
      element.appendChild(document.createElement('br'));
    }
  });
}

export const render: RenderFunction<'text'> = (
  element,
  { text, blockAttributes, rawElementAttributes },
) => {
  const elementAttributes = createElementAttributes(element, rawElementAttributes);
  rewriteTextWithLinebreak(element, text);
  setAttributesToBlock({ element, blockAttributes, elementAttributes });
};

export const create = (): RenderModule<'text'> => {
  return {
    render,
    shouldRender: shouldRenderWhenNotRenderedYet,
  };
};
