import { makeReduceKey } from '../window/reduceKey';
var FirstStats = /** @class */ (function () {
    function FirstStats() {
    }
    FirstStats.prototype.dataType = function () {
        return 'any';
    };
    FirstStats.prototype.initialValue = function () {
        return { v: undefined, k: 0 };
    };
    FirstStats.prototype.calculateFromValue = function (oldValue, value) {
        var newValue = this.calculate(oldValue, value);
        return newValue;
    };
    FirstStats.prototype.calculateFromStats = function (oldValue, value) {
        var newValue = this.calculate(oldValue, value.v);
        return newValue;
    };
    FirstStats.prototype.gc = function (values) {
        return values;
    };
    FirstStats.prototype.calculate = function (oldValue, value) {
        if (oldValue.v != null) {
            return oldValue;
        }
        else {
            var newValue = {
                v: value,
                k: makeReduceKey(),
            };
            return newValue;
        }
    };
    return FirstStats;
}());
export default new FirstStats();
