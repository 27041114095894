import { SlidingWindowCalculator, WindowType } from '../window';
// 4週間
var SPAN = 7 * 4;
var MonthTimeWindow = /** @class */ (function () {
    function MonthTimeWindow(storageSet) {
        this.storageSet = storageSet;
    }
    MonthTimeWindow.prototype.getCalculator = function () {
        return new SlidingWindowCalculator(this.storageSet, WindowType.WEEK, SPAN);
    };
    MonthTimeWindow.prototype.getKey = function () {
        return 'MONTH';
    };
    return MonthTimeWindow;
}());
export { MonthTimeWindow };
