import * as cookie from './cookie';

// サブドメインで複数のユーザーに使用されることが想定されるドメインにはcookieを発行せず、
// eLTD + 2 をcookieのdomain optionに指定する
const EXTEND_PSL = ['stores.jp', 'hatenablog.com'];

const isSettableDomain = (domain: string) => {
  // randomなkeyを生成
  const key = Math.random().toString(32).substring(2);
  const options: cookie.CookieOptions = {
    expires: new Date(new Date().getTime() + 1000),
    domain: domain,
    secure: true,
  };

  if (EXTEND_PSL.indexOf(domain) != -1) {
    return false;
  }

  cookie.set(key, domain, options);

  const isGettable = !!cookie.get(key);
  // remove
  const removeOptions: cookie.CookieOptions = {
    maxAge: 0,
  };
  cookie.set(key, '', removeOptions);

  return isGettable;
};

const getDomain = (urlStr: string): string | undefined => {
  const pieces = new URL(urlStr).hostname.split('.').reverse();
  let testDomain = pieces.shift();
  for (const part of pieces) {
    testDomain = testDomain ? part + '.' + testDomain : part;
    if (isSettableDomain(testDomain)) {
      return testDomain;
    }
  }

  if (location.hostname === 'localhost') {
    return location.host;
  }
};

export { getDomain };
