var MemoryStorage = /** @class */ (function () {
    function MemoryStorage() {
        this.storage = {};
    }
    MemoryStorage.prototype.get = function (key) {
        return this.storage[key];
    };
    MemoryStorage.prototype.set = function (key, val) {
        this.storage[key] = val;
    };
    MemoryStorage.prototype.del = function (key) {
        delete this.storage[key];
    };
    MemoryStorage.prototype.getAll = function () {
        var _this = this;
        // shallow copy
        var o = {};
        Object.keys(this.storage).forEach(function (k) {
            o[k] = _this.storage[k];
        });
        return o;
    };
    return MemoryStorage;
}());
export default MemoryStorage;
