import { get } from './storage-util';
const OPTOUT_KEY = 'blocks_oo';

function isOptoutUser(): boolean {
  const getFromLocal = get(window.localStorage);
  const hasOptoutKeyOnLocalStorage = getFromLocal<boolean>(OPTOUT_KEY);
  const hasOptoutCookie = document.cookie.indexOf(OPTOUT_KEY) !== -1;
  if (hasOptoutCookie || hasOptoutKeyOnLocalStorage) {
    return true;
  }
  return false;
}

export { isOptoutUser };
