var LocalStorage = /** @class */ (function () {
    function LocalStorage(storagePrefixKey) {
        this.storagePrefixKey = storagePrefixKey;
    }
    LocalStorage.prototype._getObj = function () {
        var item = window.localStorage.getItem(this.storagePrefixKey) || '{}';
        return JSON.parse(item);
    };
    LocalStorage.prototype._setObj = function (obj) {
        window.localStorage.setItem(this.storagePrefixKey, JSON.stringify(obj));
    };
    LocalStorage.prototype.get = function (key) {
        return this._getObj()[key];
    };
    LocalStorage.prototype.set = function (key, val) {
        var obj = this._getObj();
        obj[key] = val;
        this._setObj(obj);
    };
    LocalStorage.prototype.del = function (key) {
        var obj = this._getObj();
        delete obj[key];
        this._setObj(obj);
    };
    LocalStorage.prototype.getAll = function () {
        var obj = this._getObj();
        // shallow copy
        var o = {};
        Object.keys(obj).forEach(function (k) {
            o[k] = obj[k];
        });
        return o;
    };
    return LocalStorage;
}());
export default LocalStorage;
