import { isStringValue } from '../guard';
import { getDimensionStorage } from '../storage/dimensionStorage';
import RegularExpression from './RegularExpression';
import { edgeDimensionTypes as t } from 'rewrite-common';
var Matcher = /** @class */ (function () {
    function Matcher(storageType) {
        this.dimensionStorage = getDimensionStorage(storageType);
    }
    // 配信条件で設定できるセグメントでの条件は内側がOR, 外側がANDになっている
    Matcher.prototype.isDimensionMatchToConditions = function (matchConfig, currentDimensions) {
        var _this = this;
        if (!matchConfig || matchConfig.length == 0)
            return true;
        return matchConfig.some(function (conditions) {
            if (conditions.length == 0)
                return true;
            // blocks dimension がセグメントの条件に合っているか
            return conditions.every(function (condition) {
                var dimensionIdKey = _this.dimensionStorage.getDimensionIdKey(condition.id);
                var dimension = currentDimensions[dimensionIdKey];
                return _this.matchCondition(condition, dimension);
            });
        });
    };
    // BLOCKS セグメントでの条件は内側がANDで繋がっている
    Matcher.prototype.isDimensionMatchToBlocksSegment = function (matchConfig, currentDimensions) {
        var _this = this;
        if (!matchConfig || matchConfig.length == 0)
            return true;
        return matchConfig.every(function (conditions) {
            if (conditions.length == 0)
                return true;
            return conditions.some(function (condition) {
                var dimensionIdKey = _this.dimensionStorage.getDimensionIdKey(condition.id);
                var dimension = currentDimensions[dimensionIdKey];
                return _this.matchCondition(condition, dimension);
            });
        });
    };
    Matcher.prototype.matchCondition = function (condition, dimension) {
        if (!dimension) {
            return false;
        }
        var dimensionValue = dimension.v;
        // return false if value has not been initialized yet
        if (dimensionValue == null)
            return false;
        if (isStringValue(condition.value)) {
            var regExp = new RegularExpression(condition.value, condition.compare, condition.isRegex);
            return regExp.test(String(dimensionValue));
        }
        switch (condition.compare) {
            case t.CompareType.EQ:
                return dimensionValue == condition.value;
            case t.CompareType.NE:
                return dimensionValue != condition.value;
            case t.CompareType.GT:
                // @ts-ignore
                return dimensionValue > condition.value;
            case t.CompareType.GTE:
                // @ts-ignore
                return dimensionValue >= condition.value;
            case t.CompareType.LT:
                // @ts-ignore
                return dimensionValue < condition.value;
            case t.CompareType.LTE:
                // @ts-ignore
                return dimensionValue <= condition.value;
            default:
                break;
        }
        return false;
    };
    return Matcher;
}());
export { Matcher };
