export const createActionTableRowResolver = (context, variablesQuery) => {
    const DEFAULT_VALUE = {};
    async function resolve(resolvedVariables) {
        const variableForKey = variablesQuery.query.key;
        const key = resolvedVariables[variableForKey];
        if (typeof key !== 'string') {
            throw new Error('invalid type');
        }
        const value = await context.actionTableClient.getByKey({
            tableName: variablesQuery.query.table_name,
            key,
        });
        return {
            [variablesQuery.name]: value ?? variablesQuery.query.default_value,
        };
    }
    async function resolvePreview() {
        return {
            [variablesQuery.name]: variablesQuery.preview_value ?? DEFAULT_VALUE,
        };
    }
    function getInitial() {
        return {
            [variablesQuery.name]: DEFAULT_VALUE,
        };
    }
    return {
        resolve,
        resolvePreview,
        getInitial,
    };
};
export const createActionTableRowsResolver = (context, variablesQuery) => {
    const DEFAULT_VALUE = [];
    async function resolve(resolvedVariables) {
        const keys = [];
        variablesQuery.query.keys.forEach(variable => {
            const key = resolvedVariables[variable];
            if (typeof key === 'string') {
                keys.push(key);
            }
        });
        const value = await context.actionTableClient.getByKeys({
            tableName: variablesQuery.query.table_name,
            keys,
        });
        return {
            [variablesQuery.name]: value,
        };
    }
    async function resolvePreview() {
        return {
            [variablesQuery.name]: variablesQuery.preview_value ?? DEFAULT_VALUE,
        };
    }
    function getInitial() {
        return {
            [variablesQuery.name]: DEFAULT_VALUE,
        };
    }
    return {
        resolve,
        resolvePreview,
        getInitial,
    };
};
export const createActionTableQueryResolver = (context, variablesQuery) => {
    const DEFAULT_VALUE = [];
    async function resolve(resolvedVariables) {
        const params = {};
        Object.entries(variablesQuery.query.params ?? {}).forEach(([key, variable]) => {
            if (typeof variable === 'string') {
                const value = resolvedVariables[variable];
                if (value) {
                    params[key] = value;
                }
            }
        });
        const value = await context.actionTableClient.getByQuery({
            tableName: variablesQuery.query.table_name,
            queryName: variablesQuery.query.query_name,
            queryParams: params,
            queryOptions: variablesQuery.query.options,
        });
        return {
            [variablesQuery.name]: value,
        };
    }
    async function resolvePreview() {
        return {
            [variablesQuery.name]: variablesQuery.preview_value ?? DEFAULT_VALUE,
        };
    }
    function getInitial() {
        return {
            [variablesQuery.name]: variablesQuery.query.placeholder_value ?? DEFAULT_VALUE,
        };
    }
    return {
        resolve,
        resolvePreview,
        getInitial,
    };
};
