import { ElementAttributesOptions } from './ElementAttributes';

/**
 * alt属性
 */
export type ElementAttributeAlt = {
  key: 'alt';
  value: string;
};
export const elementAttributeAlt = ({
  variation,
}: ElementAttributesOptions): ElementAttributeAlt[] => {
  const elementAttributes = variation.elementAttributes;
  return elementAttributes.alt ? [{ key: 'alt', value: elementAttributes.alt }] : [];
};
