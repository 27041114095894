/**
 * `element`を`wrapperElement`で包むように移動する
 *
 * @example
 *
 * const div = document.createElement("div");
 * wrapElement(document.querySelector("img"), div);
 *
 * 次のようにdivでラップする
 *
 *  <img />
 *
 *  →
 *
 *  <div>
 *    <img />
 *  </div>
 *
 * @param element
 * @param wrapperElement
 * @returns 追加に成功ならwrapperElementを返す、失敗ならundefinedを返す
 */
export const wrapElement = <T extends Element>(
  element: Element,
  wrapperElement: T,
): T | undefined => {
  if (!element.parentNode) {
    return;
  }
  element.parentNode.insertBefore(wrapperElement, element);
  wrapperElement.appendChild(element);
  return wrapperElement;
};
