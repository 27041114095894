import { RenderFunction, RenderModule } from './types';
import { setAttributesToBlock, shouldRenderWhenNotRenderedYet } from './utils';

export const render: RenderFunction<'original'> = (element, { blockAttributes }) => {
  setAttributesToBlock({
    element,
    blockAttributes,
  });
};

export const create = (): RenderModule<'original'> => {
  return {
    render,
    shouldRender: shouldRenderWhenNotRenderedYet,
  };
};
