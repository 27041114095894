import { ElementAttributesOptions } from './ElementAttributes';

/**
 * class属性
 */
export type ElementAttributeClass = {
  key: 'class';
  value: string;
};
export const elementAttributeClass = ({
  element,
  variation,
}: ElementAttributesOptions): ElementAttributeClass[] => {
  const elementAttributes = variation.elementAttributes;
  // # class属性
  // classは既存のclassとマージするため、実際に追加するクラス名のみを取得する
  // 要素にクラス名が含まれているかをチェックして、含まれていないものだけを取り出す
  // https://developer.mozilla.org/ja/docs/Web/API/Element/classList
  const additionalClassNames = elementAttributes.className
    ? elementAttributes.className.split(/\s+/).filter(className => {
        return !element.classList.contains(className);
      })
    : [];
  const hasOriginalClassAttribute = element.className !== '';
  const classNamePadding = hasOriginalClassAttribute ? ' ' : '';
  // 書き換えは不要な場合は返す
  return additionalClassNames.length > 0
    ? [
        {
          key: 'class',
          value: element.className + classNamePadding + additionalClassNames.join(' '),
        },
      ]
    : [];
};
