export var StatsType;
(function (StatsType) {
    StatsType["SUM"] = "SUM";
    StatsType["AVG"] = "AVG";
    StatsType["MIN"] = "MIN";
    StatsType["MAX"] = "MAX";
    StatsType["CNT"] = "CNT";
    StatsType["FIRST"] = "FIRST";
    StatsType["LAST"] = "LAST";
    StatsType["PREV"] = "PREV";
    StatsType["MATCH"] = "MATCH";
    StatsType["PARTIAL_MATCH"] = "PARTIAL_MATCH";
})(StatsType || (StatsType = {}));
export var TimeWindowType;
(function (TimeWindowType) {
    TimeWindowType["ALL"] = "ALL";
    TimeWindowType["DAY"] = "DAY";
    TimeWindowType["WEEK"] = "WEEK";
    TimeWindowType["MONTH"] = "MONTH";
    TimeWindowType["SESSION"] = "SESSION";
    TimeWindowType["PREV_SESSION"] = "PREV_SESSION";
    TimeWindowType["_TEST"] = "_TEST";
})(TimeWindowType || (TimeWindowType = {}));
export var StorageType;
(function (StorageType) {
    StorageType[StorageType["MEMORY"] = 0] = "MEMORY";
    StorageType[StorageType["LOCAL_STORAGE"] = 1] = "LOCAL_STORAGE";
})(StorageType || (StorageType = {}));
export var CompareType;
(function (CompareType) {
    CompareType["LTE"] = "lte";
    CompareType["LT"] = "lt";
    CompareType["GTE"] = "gte";
    CompareType["GT"] = "gt";
    CompareType["EQ"] = "eq";
    CompareType["NE"] = "ne";
    CompareType["MF"] = "match_forward";
    CompareType["MP"] = "match_partial";
    CompareType["MB"] = "match_backward";
    CompareType["NMF"] = "not_match_forward";
    CompareType["NMP"] = "not_match_partial";
    CompareType["NMB"] = "not_match_backward";
})(CompareType || (CompareType = {}));
