import Ingest from '.';
import { observeMutation } from '../observer';
export default (function (config) {
    var ingest = new Ingest(config.apiKey, config.ingestOptions);
    ingest.trackGlobalCustomEvents();
    observeMutation(document.documentElement || document.body, {
        onUrlChanged: function () {
            ingest.trackGlobalCustomEvents();
        },
    });
});
