import { edgeDimensionTypes } from 'rewrite-common';
import { dimensionMatcher, Matcher } from 'rewrite-dimension-lib';
import { PopulatedSegmentForBuilder } from 'rewrite-common/lib/types/segment_types';
import { LOCAL_KEY } from './store';

const matcher = new Matcher(edgeDimensionTypes.StorageType.LOCAL_STORAGE);

function getMatchedBlocksSegments(segments: PopulatedSegmentForBuilder[]): string[] {
  const currentDimensions = dimensionMatcher.dimensionStorage.getAllDimensions();
  const matchedBlocksSegments: string[] = [];
  segments.forEach(s => {
    const conditions = s.conditions.map(c => {
      return c.dimensionConditions.map(dimensionCondition => {
        return {
          id: dimensionCondition.dimensionId,
          compare: dimensionCondition.compare,
          isRegex: dimensionCondition.isRegex,
          value: dimensionCondition.value,
        };
      });
    }) as edgeDimensionTypes.MatchConditions;

    if (matcher.isDimensionMatchToBlocksSegment(conditions, currentDimensions)) {
      matchedBlocksSegments.push(s._id.toString());
    }
  });

  return matchedBlocksSegments;
}

export function getMatchedBlocksSegmentsForTest(segments: PopulatedSegmentForBuilder[]): string[] {
  return getMatchedBlocksSegments(segments);
}

// 一旦 localStorage に入れておいて、createState の中に直接値渡ししないようにする
// localStorage に expire をつけていないのは、毎回 builderjs が走る時にこのキーが初期化されて入ることを想定しているから
export function setMatchedBlocksSegments(segments: PopulatedSegmentForBuilder[] | undefined): void {
  // マッチするセグメントが一個もない場合には localStorage に何もセットしないため
  window.localStorage.removeItem(LOCAL_KEY.KRT_BLOCKS_SEGMENTS);
  if (!segments) {
    return;
  }

  const matchedBlocksSegments = getMatchedBlocksSegments(segments);
  if (matchedBlocksSegments.length === 0) {
    return;
  }

  window.localStorage.setItem(LOCAL_KEY.KRT_BLOCKS_SEGMENTS, matchedBlocksSegments.join(','));
}
