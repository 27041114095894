import { getDimensionHistoryStorage, } from '../storage/dimensionHistoryStorage';
import { getDimensionStorage } from '../storage/dimensionStorage';
import { getTimeWindowKeyStorage } from '../storage/timeWindowKeyStorage';
import { changeTimeWindow, getTimeWindow, initializeTimeWindow } from '../time-window';
import * as _ from '../utils/lodash';
var Calculator = /** @class */ (function () {
    function Calculator(config, storageType) {
        this.config = config;
        this.storageType = storageType;
        // compile JSON
        this.timeWindowKeyStorage = getTimeWindowKeyStorage(storageType);
        this.dimensionStorage = getDimensionStorage(storageType);
        this.dimensionHistoryStorage = getDimensionHistoryStorage(storageType);
        this.timeWindowChangeListeners = [];
        initializeTimeWindow(this.storageSet);
    }
    Object.defineProperty(Calculator.prototype, "storageSet", {
        get: function () {
            return {
                storageType: this.storageType,
                dimensionStorage: this.dimensionStorage,
                dimensionHistoryStorage: this.dimensionHistoryStorage,
                timeWindowKeyStorage: this.timeWindowKeyStorage,
            };
        },
        enumerable: false,
        configurable: true
    });
    Calculator.prototype.update = function (values) {
        var _this = this;
        // timewindowが切り替わった場合、古いものは削除
        var timeWindows = changeTimeWindow();
        // dimensionをcheck
        this.config.dimensions.forEach(function (dimension) {
            _this.updateDimension(dimension, values);
        });
        timeWindows.forEach(function (tw) {
            _this.timeWindowChangeListeners.forEach(function (listener) { return listener(tw); });
        });
    };
    Calculator.prototype.addTimeWindowChangeListener = function (listener) {
        if (this.timeWindowChangeListeners.indexOf(listener) > -1) {
            return;
        }
        this.timeWindowChangeListeners.push(listener);
    };
    Calculator.prototype.removeTimeWindowChangeListener = function (listener) {
        var index = this.timeWindowChangeListeners.indexOf(listener);
        if (index < 0) {
            return;
        }
        this.timeWindowChangeListeners.splice(index, 1);
    };
    Calculator.prototype.updateDimension = function (dimension, values) {
        // 取り出す
        var value = _.get(values, dimension.path, null);
        // ignore null, undefined
        if (value == null)
            return;
        var calculator = getTimeWindow(dimension.tw).getCalculator();
        calculator.calculate(dimension, value);
    };
    return Calculator;
}());
export default Calculator;
