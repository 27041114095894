import { edgeDimensionTypes as t } from 'rewrite-common';
import _ingestInit from './ingest/init';
import init from './init';
import { Matcher } from './matcher';
// 初期化
export var dimensionInit = init;
// matcher with localstorage
export { Matcher };
export var dimensionMatcher = new Matcher(t.StorageType.LOCAL_STORAGE);
// ingest初期化
export var ingestInit = _ingestInit;
