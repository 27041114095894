import { get } from './storage-util';

// debug用。ここでしか使わない。
const LOCAL_KEYS = {
  KRT_REWRITE_CONTROL_GROUP_DISABILITY: 'krt_rewrite_control_group_disability',
  KRT_REWRITE_IS_CONTROL_FOR_ALL: 'krt_rewrite_is_control_for_all',
  KRT_REWRITE_IS_CONTROL_FOR_CONDITION: 'krt_rewrite_is_control_for_condition',
};

// ユーザ全体の10%はコントロール群
function isInControlGroupForAll(hasControlForAll: boolean, uid: string): boolean {
  const getFromLocal = get(window.localStorage);
  if (getFromLocal<boolean>(LOCAL_KEYS.KRT_REWRITE_CONTROL_GROUP_DISABILITY)) return false; // disable control group されてたらcontrol groupに入ることはない
  if (getFromLocal<boolean>(LOCAL_KEYS.KRT_REWRITE_IS_CONTROL_FOR_ALL)) return true; // force true when debugging mode

  if (!hasControlForAll) return false; // control for all 無しならcontrol groupに入ることはない
  return parseInt(uid.slice(-2), 16) % 10 === 0;
}

/**
 * Conditionごとのcontrol groupを生成
 * とりあえず10%固定にしているけど仕様変更になりそう
 * もっとうまい方法がありそう
 */
function isInControlGroupForCondition(
  condition: { randomVal: number; hasControl?: boolean; controlPatternProportion: number },
  conditionVal: number,
): boolean {
  const getFromLocal = get(window.localStorage);
  if (getFromLocal<boolean>(LOCAL_KEYS.KRT_REWRITE_CONTROL_GROUP_DISABILITY)) return false; // disable control group されてたらcontrol groupに入ることはない
  if (getFromLocal<boolean>(LOCAL_KEYS.KRT_REWRITE_IS_CONTROL_FOR_CONDITION)) return true; // force true when debugging mode

  const { randomVal, hasControl, controlPatternProportion } = condition;
  if (!hasControl) return false; // control for condition 無しなら control group に入ることはない
  return (randomVal + conditionVal) % 100 < controlPatternProportion;
}

/**
 * control group に入る割合の計算
 */
function calcControlPatternProportion(patterns: Readonly<{ proportion: number }[]>): number {
  let sum = 0;
  patterns.forEach(pattern => {
    sum += pattern.proportion;
  });
  const result = 100 - sum;
  return result;
}

/**
 * contorl pattern propotion > 0 だったら control group に入る可能性がある
 */
function hasControl(controlPatternProportion: number): boolean {
  return controlPatternProportion > 0;
}

export {
  LOCAL_KEYS,
  isInControlGroupForAll,
  isInControlGroupForCondition,
  calcControlPatternProportion,
  hasControl,
};
