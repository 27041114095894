import { RenderFunction, RenderModule } from './types';
import {
  shouldRenderWhenNotRenderedYet,
  setAttributesToBlock,
  executeScript,
  appendCSSToElement,
} from './utils';

export const render: RenderFunction<'before-html' | 'blocks-element-before'> = (
  element,
  { html, style, script, blockAttributes, variationId },
) => {
  if (!html) return;
  const fragment = document.createElement('template');
  fragment.innerHTML = html;
  const renderedElement = fragment.content?.firstElementChild || fragment.firstElementChild;
  if (!renderedElement) return;
  if (style) appendCSSToElement(renderedElement, style);
  setAttributesToBlock({ element: renderedElement, blockAttributes, elementAttributes: [] });
  element.parentNode?.insertBefore?.(renderedElement, element);
  if (script) executeScript({ script, variationId });
};

export const create = (): RenderModule<'before-html' | 'blocks-element-before'> => {
  return {
    render,
    shouldRender: shouldRenderWhenNotRenderedYet,
  };
};
