import { FixedWindowCalculator } from '../window';
var INTERVAL = 50; // 50ms で切り替わる
var start = Date.now();
var TestTimeWindow = /** @class */ (function () {
    function TestTimeWindow(storageSet) {
        this.storageSet = storageSet;
    }
    TestTimeWindow.prototype.getCalculator = function () {
        return new FixedWindowCalculator(this.storageSet);
    };
    TestTimeWindow.prototype.getKey = function () {
        var now = Date.now();
        return "".concat(Math.floor((now - start) / INTERVAL), "/").concat(INTERVAL);
    };
    return TestTimeWindow;
}());
export { TestTimeWindow };
