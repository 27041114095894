import { set, get } from './storage-util';
import { LOCAL_KEYS as CONTROL_GROUP_LOCAL_KEYS } from './control_group';
import { LOCAL_KEYS as LOGGER_LOCAL_KEYS } from './logger';

// expose util to global
function exposeDebuggerUtilityToGlobal(): void {
  if (!window.__KARTE_REWRITE_ADMIN_CONFIG) window.__KARTE_REWRITE_ADMIN_CONFIG = {};
  window.__KARTE_REWRITE_ADMIN_CONFIG.util = {
    show_state_for_debug: () => {
      const getFromLocal = get(window.localStorage);
      return {
        isControlForAllForDebug: getFromLocal(
          CONTROL_GROUP_LOCAL_KEYS.KRT_REWRITE_IS_CONTROL_FOR_ALL,
        ),
        isControlForConditionForDebug: getFromLocal(
          CONTROL_GROUP_LOCAL_KEYS.KRT_REWRITE_CONTROL_GROUP_DISABILITY,
        ),
        controlGroupDisability: getFromLocal(
          CONTROL_GROUP_LOCAL_KEYS.KRT_REWRITE_CONTROL_GROUP_DISABILITY,
        ),
        loggerEnability: getFromLocal(LOGGER_LOCAL_KEYS.KRT_REWRITE_LOGGER_ENABLE),
      };
    },
    disable_control_group: (flag: boolean) => {
      const setToLocal = set(window.localStorage);
      setToLocal(CONTROL_GROUP_LOCAL_KEYS.KRT_REWRITE_CONTROL_GROUP_DISABILITY, flag);
    },
    enable_logger: (flag: boolean) => {
      const setToLocal = set(window.localStorage);
      setToLocal(LOGGER_LOCAL_KEYS.KRT_REWRITE_LOGGER_ENABLE, flag);
    },
    be_control_group_for_all: (flag: boolean) => {
      const setToLocal = set(window.localStorage);
      setToLocal(CONTROL_GROUP_LOCAL_KEYS.KRT_REWRITE_IS_CONTROL_FOR_ALL, flag);
    },
    be_control_group_for_condition: (flag: boolean) => {
      const setToLocal = set(window.localStorage);
      setToLocal(CONTROL_GROUP_LOCAL_KEYS.KRT_REWRITE_IS_CONTROL_FOR_CONDITION, flag);
    },
  };
}

/**
 * if this excution is not first excution
 * then throw Error
 */
function preventDoubleExecution(): void {
  if (!window.__KARTE_REWRITE_ADMIN_CONFIG) window.__KARTE_REWRITE_ADMIN_CONFIG = {};
  if (window.__KARTE_REWRITE_ADMIN_CONFIG.IS_EXECUTED_BUILDERJS)
    throw new Error(
      'builder.js cannot be loaded more than twice. Please check whether this script is loaded only once or not.',
    );
  window.__KARTE_REWRITE_ADMIN_CONFIG.IS_EXECUTED_BUILDERJS = true;
}

function existsSkippingQuery(): boolean {
  const queriesStr = window.location.search.slice(1);
  const queries = queriesStr.split('&');
  const doesSkip = queries.some(query => /^krt_blocks_skip_builderjs/.test(query));
  return doesSkip;
}

export { exposeDebuggerUtilityToGlobal, preventDoubleExecution, existsSkippingQuery };
