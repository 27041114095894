import { getStorage } from './Storage';
var DimensionHistoryStorage = /** @class */ (function () {
    function DimensionHistoryStorage(storageType) {
        this.storage = getStorage(storageType, 'krtdimh');
    }
    DimensionHistoryStorage.prototype.getDimensionHistoryKey = function (id, tw) {
        return "d-".concat(id, "-").concat(tw);
    };
    DimensionHistoryStorage.prototype.getDimensionHistory = function (dimension) {
        var key = this.getDimensionHistoryKey(dimension.id, dimension.tw);
        return this.getDimensionHistoryFromKey(key);
    };
    DimensionHistoryStorage.prototype.getDimensionHistoryFromKey = function (key) {
        var values = this.storage.get(key);
        return values != null ? values : [];
    };
    DimensionHistoryStorage.prototype.getDimensionHistoryWithKey = function () {
        // キーから dimensionId と tw を抜く
        var re = new RegExp('^d-(.+?)-(.+)$', 'i');
        var values = this.storage.getAll();
        return Object.keys(values)
            .map(function (key) {
            return (re.exec(key) && { id: RegExp.$1, tw: RegExp.$2, values: values[key] }) || null;
        })
            .filter(Boolean);
    };
    DimensionHistoryStorage.prototype.setDimensionHistory = function (dimension, values) {
        var key = this.getDimensionHistoryKey(dimension.id, dimension.tw);
        this.storage.set(key, values);
    };
    DimensionHistoryStorage.prototype.removeDimensionHistoryFromKey = function (key) {
        this.storage.del(key);
    };
    return DimensionHistoryStorage;
}());
export { DimensionHistoryStorage };
export function getDimensionHistoryStorage(storageType) {
    return new DimensionHistoryStorage(storageType);
}
