'use strict';
const escape = (str) => {
    return str.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
};
const testUrlV0 = (urlStr, urlCondition) => {
    const url = new URL(urlStr);
    const queries = url.search.substring(1);
    const value = urlCondition.url.value;
    const escapeStr = urlCondition.url.isRegex ? value : escape(value);
    const result = escapeStr + '/?';
    const regStr = checkComparisonType(urlCondition.compares, result);
    const reg = new RegExp(regStr);
    if (!reg.test(url.origin + url.pathname)) {
        return false;
    }
    return testQuery(queries, urlCondition.queries);
};
const testQuery = (queries, queryCondition) => {
    const queryList = queries.split('&');
    if (!queryList || queryList.length <= 0)
        return true;
    const result = queryCondition.every(c => {
        const regStr = c.isRegex ? c.value : `^${escape(c.value)}$`;
        const reg = RegExp(regStr);
        return queryList.some(q => {
            return reg.test(q);
        });
    });
    return result;
};
const checkComparisonType = (comparisonType, urlStr) => {
    switch (comparisonType) {
        case 'default_equal':
            return `^${urlStr}$`;
        case 'string_partial_match':
            return urlStr;
        case 'string_forward_match':
            return `^${urlStr}`;
        case 'string_backward_match':
            return `${urlStr}$`;
        case 'regex_match':
            return urlStr;
        default:
            throw new Error(`invalid URL comparisonType: ${comparisonType}`);
    }
};
const testUrlV1 = (urlStr, goalUrl, matchType, goalUrlCondition) => {
    if (matchType === 'regex_match') {
        return testRegexMatch(urlStr, goalUrl);
    }
    else {
        const eventUrl = new URL(urlStr);
        const protocol = goalUrlCondition.protocol ? goalUrlCondition.protocol + '://' : '';
        const hostname = goalUrlCondition.hostname;
        const path = goalUrlCondition.path ? '/' + goalUrlCondition.path : '';
        const search = goalUrlCondition.search;
        const originAndPath = checkComparisonType(matchType, protocol + hostname + path);
        const regGoalUrl = new RegExp(originAndPath);
        if (!regGoalUrl.test(eventUrl.origin + eventUrl.pathname)) {
            return false;
        }
        if (!search) {
            return true;
        }
        const searchOfEventUrl = eventUrl.search.substring(1).split('&');
        const searchOfGoalUrlCondition = search.split('&');
        return searchOfGoalUrlCondition.every(c => {
            const reg = RegExp(`^${c}$`);
            return searchOfEventUrl.some(q => {
                return reg.test(q);
            });
        });
    }
};
const testRegexMatch = (urlStr, regex) => {
    const reg = new RegExp(regex);
    return reg.test(urlStr);
};
export { testUrlV0 };
export { testUrlV1 };
export { testRegexMatch };
