export default (function (tracker, tw) {
    var _a;
    var values = (_a = {},
        _a[tw] = {
            key_change: 1,
            date: Date.now(),
        },
        _a);
    // pageview event
    tracker.track('time_window', values);
});
