import { createActionTableQueryResolver, createActionTableRowResolver, createActionTableRowsResolver, } from './actionTable';
function createVariableQueryResolver(context, variableQuery) {
    switch (variableQuery.resolver) {
        case 'action-table-row':
            return createActionTableRowResolver(context, variableQuery);
        case 'action-table-rows':
            return createActionTableRowsResolver(context, variableQuery);
        case 'action-table-query':
            return createActionTableQueryResolver(context, variableQuery);
        default:
            console.warn(`Unknown resolver: ${variableQuery.resolver}`);
            return {
                resolve: async () => ({}),
                resolvePreview: async () => ({}),
                getInitial: () => ({}),
            };
    }
}
export function createClientSideVariablesQueryResolver(context, variablesQuery) {
    const resolvers = variablesQuery.map(variableQuery => createVariableQueryResolver(context, variableQuery));
    function mergeResults(arr) {
        return arr.reduce((acc, result) => ({ ...acc, ...result }), {});
    }
    async function resolve(resolvedVariables) {
        const results = await Promise.all(resolvers.map(resolver => resolver.resolve(resolvedVariables)));
        return mergeResults(results);
    }
    async function resolvePreview() {
        const results = await Promise.all(resolvers.map(resolver => resolver.resolvePreview()));
        return mergeResults(results);
    }
    function getInitial() {
        const results = resolvers.map(resolver => resolver.getInitial());
        return mergeResults(results);
    }
    return {
        resolve,
        resolvePreview,
        getInitial,
    };
}
