import { getVariables, setStorage as _setStorage } from './client';
export function setStorage(options) {
    _setStorage(options);
}
export function createServerSideVariablesQueryResolver(context, variablesQuery) {
    const DEFAULT_VALUE = {};
    async function resolve() {
        const serverSideResult = await getVariables({
            variationId: context.variationId,
            usePreviousValue: context.usePreviousValue,
        });
        const variableNamesSet = new Set(variablesQuery.map(vq => vq.name));
        return Object.fromEntries(Object.entries(serverSideResult).filter(([variableName]) => variableNamesSet.has(variableName)));
    }
    async function resolvePreview() {
        return Object.fromEntries(variablesQuery.map(vq => [vq.name, vq.preview_value ?? DEFAULT_VALUE]));
    }
    function getInitial() {
        return Object.fromEntries(variablesQuery.map(vq => [vq.name, DEFAULT_VALUE]));
    }
    return {
        resolve,
        resolvePreview,
        getInitial,
    };
}
