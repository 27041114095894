import { Raw, Builder } from './config_types';
import { State } from './store';
import { ConditionArg, SendEventArg } from '../eventSender';
import { BLOCK_ID_ATTR } from './render/utils';

function getBlockDoms(): HTMLElement[] {
  const doms = document.querySelectorAll(`[${BLOCK_ID_ATTR}]`);
  return Array.prototype.slice.call(doms);
}
export class BlocksImpressionAnalyzer {
  private readonly config: Raw.Config;
  private readonly deploymentId: string;
  private readonly state: State;
  private readonly isControlForAll: boolean;
  private readonly observer?: IntersectionObserver;
  private variations: Builder.Variation[] = [];
  private eventQueue: { [key: string]: ConditionArg[] };
  private cache: { [key: string]: boolean };
  private sendEvent?: (args: SendEventArg) => void;

  constructor(
    isControlForAll: boolean,
    config: Raw.Config,
    deploymentId: string,
    state: State,
    checkCb: (element: HTMLElement, variations: Builder.Variation[]) => Builder.Variation[],
  ) {
    this.isControlForAll = isControlForAll;
    this.config = config;
    this.deploymentId = deploymentId;
    this.state = state;
    this.eventQueue = Object.create(null);
    this.cache = Object.create(null);
    this.sendEvent = undefined;

    if (typeof IntersectionObserver === 'undefined') return;

    const options: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observerCb = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const dom = entry.target;
          const variations = checkCb(dom as HTMLElement, this.variations);
          if (variations.length === 0) return;
          variations.forEach(variation => {
            const conditionArg: ConditionArg = { ...variation };
            if (!this.eventQueue[variation.pageGroupId]) {
              this.eventQueue[variation.pageGroupId] = [conditionArg];
            } else {
              this.eventQueue[variation.pageGroupId].push(conditionArg);
            }
          });

          // 500msec待って、まとめて送信
          setTimeout(() => {
            this.sendEvents();
          }, 500);

          // 一回しか送らなくていいはずなので、一度queueにイベントをセットしたら、このdomは監視しない
          observer.unobserve?.(dom);
        }
      });
    };

    // eslint-disable-next-line compat/compat -- 存在をチェックしているため無視
    this.observer = new IntersectionObserver(observerCb, options);
  }

  private sendEvents() {
    const events = this.eventQueue;
    this.eventQueue = Object.create(null);

    const pageGroupIds = Object.keys(events);

    pageGroupIds.forEach(pageGroupId => {
      const conditionArgs = events[pageGroupId].filter(condition => {
        if (!condition.areaId && !condition.variationId) return false;
        const cacheKey = `${condition.areaId}${
          condition.variationId ? '-' + condition.variationId : ''
        }`;
        const isAlreadySent = this.cache[cacheKey];
        if (!isAlreadySent) this.cache[cacheKey] = true;
        return !isAlreadySent;
      });
      this.sendEvent?.({
        config: this.config,
        state: this.state,
        isControlForAll: this.isControlForAll,
        conditions: conditionArgs,
        pageGroupId: pageGroupId,
        deploymentId: this.deploymentId,
      });
    });
  }

  observeVariation(variations: Builder.Variation[], sendEvent: (args: SendEventArg) => void) {
    if (!this.observer) return;
    this.variations = variations;
    this.sendEvent = sendEvent;
    const elements = getBlockDoms();

    elements.forEach(element => {
      this.observer?.observe(element);
    });
  }

  disconnect() {
    if (!this.observer) return;

    this.observer.disconnect();
  }
}
